import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'

import {
  DialogContent,
  useMediaQuery,
  IconButton,
  TextField,
  useTheme,
  MenuItem,
  Divider,
  Button,
  Hidden,
  Dialog,
  Select,
  Stack,
  Tabs,
  Tab,
  FormControl
} from '@mui/material'

import {
  Add as AddIcon,
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
  Pin as PinIcon,
  Person as PersonIcon,
  Email as EmailIcon,
  MultipleStop as MultipleStopIcon,
  AccessTimeFilled as AccessTimeFilledIcon,
  InsertInvitation as InsertInvitationIcon,
  Notes as NotesIcon
} from '@mui/icons-material'


import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  removeTimezoneFromISO,
  formatDateToISOShort,
  formatDateToTimeISO,
  formatTimeFromJS,
  formatDateMedMed,
  formatDateToMed,
  formatDateToJS,
  formatTime
} from '../../common/utilities/FormatDate'

import Layout from '../../common/components/Layout/Layout'
import { api } from '../../api/api'
import '../ReservationsPage/Reservations.sass'
import './Visitors.sass'

import {
  DEFAULT_GUEST,
  EIGHT_AM
} from '../../variables'

import {
  setLoading,
  defaultSuccess,
  defaultCatch,
} from '../../state/actions'

import {
  Arrow as ArrowIcon,
} from '../../common/components/Svgs'
import SwipeableTimePicker from '../../common/components/SwipeableTimePicker/SwipeableTimePicker'
import SwipeableCalendar from '../../common/components/SwipeableCalendar/SwipeableCalendar'

import { REGEX_EMAIL } from '../../variables'

import {
  getFullNameGuest,
  getWorkplaceReasons
} from '../../common/utilities/utilities'

import {
  getNoRowOverlay,
} from '../../common/utilities/customMaterialUI'

const stackProps = {
  alignItems: "center",
  direction: "row",
  spacing: 1
}

const today = new Date()

const tableButtonGenerator = params => {
  const { field, label, callback } = params
  return {
    sortable: false,
    headerName: "",
    field,
    headerClassName: 'transparent',
    renderCell: ({ row }) => {
      return <Button
        className="table-button"
        onClick={() => callback(row)}>
        {label}
      </Button>
    }
  }
}

function Visitors(props) {
  const {
    setLoading,
    defaultSuccess,
    defaultCatch
  } = props

  const { t } = useTranslation()
  const reasons = getWorkplaceReasons(t)
  const navigate = useNavigate()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const [invitations, setInvitations] = useState([])
  const [tab, setTab] = useState('upcoming')
  const [open, setOpen] = useState(false)
  const [remove, setRemove] = useState(false)
  const [identification, setIdentification] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [comesFrom, setComesFrom] = useState('')
  const [reason, setReason] = useState(reasons[1])
  const [timePickerValue, setTimePickerValue] = useState(EIGHT_AM)
  const [entry_time, setEntryTime] = useState(EIGHT_AM)
  const [openTimePicker, setOpenTimePicker] = useState(false)
  const [item, setItem] = useState(DEFAULT_GUEST)
  const [date, setDate] = useState(null)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [calendarDate, setCalendarDate] = useState(null)
  const [emailError, setEmailError] = useState('')
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState({})
  const [currentCompanyId, setCurrentCompanyId] = useState('')

  const evalEmail = useCallback(() => {
    let error = ''

    if (
      email &&
      !REGEX_EMAIL.test(email)
    ) error = t('invalid email')

    setEmailError(error)
    return error
  }, [email, t])

  const defaultMenuItem = useCallback(() => {
    return (
      <MenuItem value="-1" disabled>
        <em>{t('select')}</em>
      </MenuItem>
    )
  }, [t])

  const clearInvitations = invitations => {
    return invitations.map(i => {
      return {
        id: i[6].value,
        dateOfVisit: i[0].value,
        name: i[1].value,
        email: i[2].value,
        comesFrom: i[3].value,
        goesTo: i[4].value,
        visitingTime: i[5].value
      }
    })
  }

  const updateEditForm = useCallback(invitation => {
    const {
      identification,
      first_name,
      last_name_one,
      email,
      comes_from,
      company_id,
      invitation_date,
      invitation_time,
      reason,
    } = invitation

    setIdentification(identification)
    setName(first_name)
    setLastname(last_name_one)
    setEmail(email)
    setComesFrom(comes_from)
    setDate(formatDateToJS(invitation_date))
    setTimePickerValue(formatDateToJS(removeTimezoneFromISO(invitation_time)))
    setEntryTime(formatDateToJS(removeTimezoneFromISO(invitation_time)))
    setReason(reason)
    setCurrentCompanyId(company_id)
    setSelectedCompany(companies.length !== 0 && companies.find(c => c.id === company_id))
  }, [companies])

  const fetchInvitationDetail = useCallback(({ id }, { editForm = false }) => {
    setLoading(true)
    api.get(`/invitation/${id}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setItem(data.invitation)
        if (editForm) updateEditForm(data.invitation)
      })
      .catch(error => defaultCatch(error, navigate, () => fetchInvitationDetail({ id }, { editForm })))
  }, [defaultCatch, defaultSuccess, navigate, setLoading, updateEditForm])

  const fetchInvitations = useCallback(() => {
    setLoading(true)
    api.get('/invitations')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setInvitations(clearInvitations(data.items))
      })
      .catch(error => defaultCatch(error, navigate, fetchInvitations))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  const fetchCompanies = useCallback(() => {
    setLoading(true)
    api.get('/companies')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const companies = data.companies
        setCompanies(companies)
      })
      .catch(error => defaultCatch(error, navigate, fetchCompanies))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  useEffect(() => {
    fetchInvitations()
    fetchCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showDetail = useCallback(_item => {
    fetchInvitationDetail(_item, { editForm: true })
    setOpen(true)
  }, [fetchInvitationDetail])

  const showDelete = useCallback(_item => {
    fetchInvitationDetail(_item, { editForm: false })
    setRemove(true)
  }, [fetchInvitationDetail])

  const getFormateDate = useCallback(() => {
    if (date) return formatDateToMed(date)
    return t('select')
  }, [date, t])

  const columns = useMemo(() => [
    { sortable: false, headerName: t('date of visit'), field: 'dateOfVisit', width: 200 },
    { sortable: false, headerName: t('name'), field: 'name', width: 200 },
    { sortable: false, headerName: t('email'), field: 'email', width: 200 },
    { sortable: false, headerName: t('comes from'), field: 'comesFrom', width: 150 },
    { sortable: false, headerName: t('goes to'), field: 'goesTo', width: 150 },
    { sortable: false, headerName: t('visiting time'), field: 'visitingTime', width: 150 },
    tableButtonGenerator({ field: "action-detail", callback: showDetail, label: t('show detail') }),
    tableButtonGenerator({ field: "action-delete", callback: showDelete, label: t('delete') })
  ], [showDelete, showDetail, t])

  const onCloseTimePicker = useCallback(() => {
    setOpenTimePicker(false)
    setTimePickerValue(entry_time)
  }, [entry_time])

  const onContinueTimePicker = useCallback(() => {
    setEntryTime(timePickerValue)
    setOpenTimePicker(false)
  }, [timePickerValue])

  const onContinue = useCallback(() => {
    setOpenCalendar(false)
    setDate(calendarDate)
    setEntryTime(EIGHT_AM)
  }, [calendarDate])

  const onCloseCalendar = useCallback(() => {
    setOpenCalendar(false)
    setCalendarDate(date)
  }, [date])

  const onUpdateGuest = useCallback(() => {
    setOpen(false)
    setLoading(true)

    const data = {
      invitation: {
        identification,
        first_name: name,
        last_name_one: lastname,
        email,
        comes_from: comesFrom,
        company_id: selectedCompany.id,
        invitation_date: formatDateToISOShort(date),
        invitation_time: formatDateToTimeISO(entry_time),
        reason
      }
    }

    api.put(`/invitation/${item.id}`, data)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        fetchInvitations()
      })
      .catch(error => defaultCatch(error, navigate, onUpdateGuest))
  }, [comesFrom, date, defaultCatch, defaultSuccess, email, entry_time, fetchInvitations, identification, item.id, lastname, name, navigate, reason, setLoading, selectedCompany])

  const removeVisitor = useCallback(() => {
    setOpen(false)
    setRemove(false)
    setLoading(true)
    api.delete(`/invitation/${item.id}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        fetchInvitations()
      })
      .catch(error => defaultCatch(error, navigate, removeVisitor))
  }, [defaultCatch, defaultSuccess, fetchInvitations, item.id, navigate, setLoading])

  const enableUpdateVisitor = useCallback(() => {
    return (
      date &&
      entry_time &&
      identification &&
      name &&
      lastname &&
      email &&
      comesFrom &&
      !emailError
    )
  }, [comesFrom, date, email, emailError, entry_time, identification, lastname, name])

  const getFormateTime = useCallback(() => {
    if (entry_time) return formatTimeFromJS(entry_time)
    return t('select')
  }, [entry_time, t])


  const onChangeEmail = event => {
    setEmail(event.target.value)
    setEmailError('')
  }

  const onChangeCompany = useCallback(event => {
    const company = event.target.value
    setSelectedCompany(companies.find(c => c.id === company))
  }, [companies])

  const detailDialog = useCallback(() => {
    return (
      <Dialog
        className="dialog-detail"
        fullScreen={!isLarge}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-detail-content">
          <h3 className='dialog-title'>
            {!isLarge &&
              <IconButton
                className="arrow-back-button"
                aria-label="back"
                color="primary"
                onClick={() => setOpen(false)}>
                <ArrowIcon className="arrow-back" />
              </IconButton>
            }
            {t('PRE REGISTRATION OF VISITORS')}
            {isLarge &&
              <IconButton
                className="close-button"
                aria-label="close"
                onClick={() => setOpen(false)} >
                <CloseIcon />
              </IconButton>
            }
          </h3>
          <Divider className="detail-hr" />
          <div className="detail-list visitor">
            <Stack spacing={2} direction="column">
              <Stack {...stackProps}>
                <PinIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('identification num.')}
                </label>
                <TextField
                  variant="outlined"
                  value={identification}
                  onChange={e => setIdentification(e.target.value)}
                  fullWidth={!isLarge}
                  hiddenLabel
                  size="small"
                />
              </Stack>
              <Stack {...stackProps}>
                <PersonIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('name')}
                </label>
                <TextField
                  variant="outlined"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  fullWidth={!isLarge}
                  hiddenLabel
                  size="small"
                />
              </Stack>
              <Stack {...stackProps}>
                <PersonIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('last name')}
                </label>
                <TextField
                  variant="outlined"
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                  fullWidth={!isLarge}
                  hiddenLabel
                  size="small"
                />
              </Stack>
              <Stack {...stackProps}>
                <EmailIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('email')}
                </label>
                <TextField
                  variant="outlined"
                  value={email}
                  error={!!emailError}
                  helperText={emailError}
                  onBlur={() => evalEmail()}
                  onChange={onChangeEmail}
                  fullWidth={!isLarge}
                  hiddenLabel
                  size="small"
                />
              </Stack>
              <Stack {...stackProps}>
                <MultipleStopIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('comes from')}
                </label>
                <TextField
                  variant="outlined"
                  value={comesFrom}
                  onChange={e => setComesFrom(e.target.value)}
                  fullWidth={!isLarge}
                  hiddenLabel
                  size="small"
                />
              </Stack>
              <Stack {...stackProps}>
                <MultipleStopIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('goes to')}
                </label>
                <FormControl fullWidth={!isLarge} >
                  <Select
                    className='select-input'
                    variant="outlined"
                    labelId="company-label"
                    fullWidth={!isLarge}
                    value={selectedCompany.id ? selectedCompany.id : currentCompanyId}
                    onChange={onChangeCompany}
                  >
                    {companies.map(c =>
                      <MenuItem
                        key={c.id}
                        value={c.id}>
                        {c.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Stack>
              <Stack {...stackProps}>
                <InsertInvitationIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('date')}
                </label>
                <TextField
                  variant="outlined"
                  value={getFormateDate()}
                  onClick={e => setOpenCalendar(true)}
                  fullWidth={!isLarge}
                  hiddenLabel
                  size="small"
                />
                <SwipeableCalendar
                  minDate={today}
                  date={calendarDate}
                  open={openCalendar}
                  onChange={date => setCalendarDate(date)}
                  setOpen={setOpenCalendar}
                  onContinue={onContinue}
                  onClose={onCloseCalendar}
                />
              </Stack>
              <Stack {...stackProps}>
                <AccessTimeFilledIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('hour')}
                </label>
                <TextField
                  variant="outlined"
                  value={getFormateTime()}
                  onClick={e => setOpenTimePicker(true)}
                  fullWidth={!isLarge}
                  hiddenLabel
                  size="small"
                />
                <SwipeableTimePicker
                  time={timePickerValue}
                  open={openTimePicker}
                  setTime={setTimePickerValue}
                  setOpen={setOpenTimePicker}
                  onContinue={onContinueTimePicker}
                  onClose={onCloseTimePicker}
                />
              </Stack>
              <Stack {...stackProps}>
                <NotesIcon className='visitor-icon' />
                <label className='visitor-label'>
                  {t('reason')}
                </label>
                <Select
                  className='select-input'
                  variant="outlined"
                  labelId="reason-label"
                  fullWidth={!isLarge}
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                >
                  {defaultMenuItem()}
                  {reasons.map((r, index) =>
                    <MenuItem
                      key={index}
                      value={r}>
                      {r}
                    </MenuItem>
                  )}
                </Select>
              </Stack>
            </Stack>
          </div>
          <Stack
            className="detail-footer"
            direction="row"
            spacing={2}
          >
            <Button
              disabled={!enableUpdateVisitor()}
              variant="contained"
              onClick={onUpdateGuest}
            >
              {t('save changes')}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setRemove(true)}
            >
              {t('delete pre-registration')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [calendarDate, comesFrom, defaultMenuItem, email, emailError, enableUpdateVisitor, evalEmail, getFormateDate, getFormateTime, identification, isLarge, lastname, name, onCloseCalendar, onCloseTimePicker, onContinue, onContinueTimePicker, onUpdateGuest, open, openCalendar, openTimePicker, reason, reasons, t, timePickerValue, selectedCompany, currentCompanyId, companies, onChangeCompany])

  const removeDialog = useCallback(() => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={remove}
        onClose={() => setRemove(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content dialog-info-visitor">
          <div className="detail-header">
            <InfoOutlinedIcon />
            {t('delete pre-registration')}
          </div>
          <Divider className="detail-hr" />
          <p>{t('delete pre-registration paragraph')}</p>
          <div className="detail-remove-info">
            <strong>{getFullNameGuest(item)}</strong><br />
            {`${formatDateMedMed(item.invitation_date)} | ${formatTime(removeTimezoneFromISO(item.invitation_time))}`}
          </div>
          <Stack spacing={2} direction="row" className='detail-footer'>
            <Button
              variant="outlined"
              onClick={() => setRemove(false)} >
              {t('go back')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={removeVisitor} >
              {t('delete')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [item, remove, removeVisitor, t])

  return (
    <Layout title={t('pre registration of visitors')}>
      <div className="Visitors">
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Tabs
            className="cp-tabs"
            value={tab}
            onChange={(_, value) => setTab(value)}
          >
            <Tab className="cp-tab" value="upcoming" label={t('upcoming visits')} />
          </Tabs>
          <Hidden mdDown>
            <Button
              className="ml-auto cp-button new-reservation-filter-button"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/new-visitor")}
            >
              {t('new pre-registration')}
            </Button>
          </Hidden>
        </Stack>
        <div className='table-wrapper'>
          <DataGrid
            disableSelectionOnClick
            rows={invitations}
            columns={columns}
            disableColumnMenu
            density="compact"
            hideFooter
            components={{
              NoRowsOverlay: () => getNoRowOverlay(theme, t)
            }}
          />
        </div>
        <Hidden mdUp>
          <div className="new-reservation-footer">
            <Button
              className="cp-button"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/new-visitor")}
            >
              {t('new pre-registration')}
            </Button>
          </div>
        </Hidden>
      </div>
      {detailDialog()}
      {removeDialog()}
    </Layout>
  )
}

const mapDispatchToProps = {
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(null, mapDispatchToProps)(Visitors)
