import { DateTime } from "luxon"
import { EQUAL, GREATER, ISO_DATE_SIZE_WITHOUT_TIME_ZONE, LESSER } from "../../variables"

const today = new Date()

const formatAMPM = (date, format = 'string') => {
  if (!(date instanceof Date) || isNaN(date)) return ''
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let amPm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  if (format === 'array') return [hours, minutes, amPm]
  return hours + ':' + minutes + ' ' + amPm
}
const removeTimezoneFromISO = time => {
  if (time.length <= ISO_DATE_SIZE_WITHOUT_TIME_ZONE) return time
  return time.slice(0, -6)
}
const removeDateFromDateTimeISO = time => time.split('T')[1]

const formatDateObj = date => DateTime.fromISO(date)  // DateTime obj
const formatDateToJS = date => DateTime.fromISO(date).toJSDate()  // Js Date 
const formatDateMed = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE) // Friday, October 14, 1983
const formatDateMedMed = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) //  Oct 14, 1983
const formatDateISOShort = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT) //13/05/2022
const formatDate = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT) // 4/5/2023
const formatDateToMed = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED) // Oct 14, 1983


function formatDateToOnlyDay(date, options = {}) {
  const { showWeekDay = false } = options
  const _date = DateTime.fromJSDate(date)

  return showWeekDay ?
    _date.toFormat('cccc, dd \'de\' LLLL') :
    _date.toFormat('dd \'de\' LLLL')
}


const formatDateToISOShort = date => DateTime.fromJSDate(date).toISODate() // '1982-05-25'
const formatDateToISO = date => removeTimezoneFromISO(DateTime.fromJSDate(date).toISO()) // '2017-04-22T20:47:05.335'

const formatTime = time => formatAMPM(DateTime.fromISO(time).toJSDate()) // 11:55 am
const formatTimeFromJS = time => formatAMPM(time) // 11:55 am
const formatTimeFromJSArray = time => formatAMPM(time, 'array') // [11, 55, "am"]
const formatDateToTimeISO = time => DateTime.fromJSDate(time).toFormat('HH:mm') // '20:47'
const formatDateToTimeHour = time => DateTime.fromJSDate(time).hour // '20'
const formatDateISOtoAMPM = time => DateTime.fromISO(time).toFormat('h:mm a') // "17:15:00" to 5:15PM
const formatNoTZTimeFromISOtoAMPM = time => DateTime.fromISO(time, { setZone: true }).toFormat('hh:mm a') // "2024-05-05T20:30:11.074-06:00" to 8:30 p.m.

const formatMultiDateToMed = (dateList = [], t) => {
  let months = []
  let item = {}

  if (dateList.length === 0) return ''

  dateList.forEach(d => {
    const month = d.getMonth()
    const index = months.findIndex(m => m.month === month)
    const date = DateTime.fromJSDate(d)
    if (index === -1) {
      item = {
        month: month,
        monthAndYear: `${t('of')} ${date.monthShort} ${date.year}`,
        days: [date.day]
      }
      months.push(item)
    } else months[index].days.push(date.day)
  })

  return months.map(item => `${item.days.join(", ")} ${item.monthAndYear}`).join(", ")
} // 14,15,16 of Oct 1983, 5 of Nov 1983  

const makeHour = ({ hour = 0, minute = 0, second = 0 } = {}) => {
  return DateTime.local().set({ hour, minute, second }).toJSDate()
}

const stringHourToJsDate = stringHour => {
  if (!stringHour) return today
  const [hour, minute] = stringHour.split(':')
  const _hour = { hour: Number(hour), minute: Number(minute), second: 0 }
  return DateTime.local().set(_hour).toJSDate() // Js Date 
}

const isToday = date => DateTime.fromISO(date).hasSame(DateTime.local(), "day") // true or false

const isTodayFromJsDate = date => {
  const today = DateTime.local()
  const _date = DateTime.fromJSDate(date)
  return today.hasSame(_date, 'day')
} // true or false

const isAllDay = ({ entry_time, exit_time }) => {

  const _entry_time = formatDateToJS(entry_time)
  const _exit_time = formatDateToJS(exit_time)

  let entry_hour = _entry_time.getHours()
  let entry_minute = _entry_time.getMinutes()
  let exit_hour = _exit_time.getHours()
  let exit_minute = _exit_time.getMinutes()

  return (
    entry_hour === 0 &&
    entry_minute === 0 &&
    exit_hour === 23 &&
    exit_minute === 59
  )
} // true or false

const getCurrentHour = (plusHour = 0, plusMinutes = 0) => {
  return DateTime.now().plus({
    hour: plusHour,
    minutes: plusMinutes,
  }).toJSDate()
}

const addMinutesToJsDate = (date, minutesToAdd) => {
  return DateTime.fromJSDate(date).plus({ minutes: minutesToAdd }).toJSDate()
}

const getUpcomingDates = dates => {
  const today = DateTime.local().startOf('day')
  return dates.filter(date => {
    const _date = DateTime.fromISO(date)
    return _date >= today
  })
}

const getFormateRangeSessionTime = (session, t) => {
  const { start_time, end_time } = session
  if (!start_time) return <i>{t('Has not yet marked arrival')}</i>
  if (!end_time) return formatTime(start_time)
  return `${formatTime(start_time)} - ${formatTime(end_time)}`
}

const getTime = (item, t) => {
  const {
    entry_time = '',
    exit_time = ''
  } = item

  const _entry_time = removeTimezoneFromISO(entry_time)
  const _exit_time = removeTimezoneFromISO(exit_time)

  if (isAllDay({ entry_time, exit_time })) return t('all day')
  return `${formatTime(_entry_time)} - ${formatTime(_exit_time)}`
}

const entryTimeIsLaterEqualThanExitTime = (entry_time, exit_time) => {
  if (!entry_time || !exit_time) return false
  const entry = formatDateToTimeISO(entry_time)
  const exit = formatDateToTimeISO(exit_time)
  return (entry >= exit)
}

const compareTimes = (time1, time2) => {
  const hours1 = time1.getHours()
  const minutes1 = time1.getMinutes()

  const hours2 = time2.getHours()
  const minutes2 = time2.getMinutes()

  if (hours1 < hours2) return LESSER
  else if (hours1 > hours2) return GREATER

  if (minutes1 < minutes2) return LESSER
  else if (minutes1 > minutes2) return GREATER
  return EQUAL
}

const getExactHour = (date, fromIso = false) => {
  const dateObj =
    fromIso ?
      new Date(removeTimezoneFromISO(date)) :
      date
  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes()
  const seconds = dateObj.getSeconds()
  const totalTime = hours + minutes / 60 + seconds / 3600
  return totalTime.toFixed(2)
}

const decomposeDecimalTime = (decimalTime, scale = 60) => {
  const _hour = decimalTime / scale
  const hour = Math.floor(_hour)
  const minuteDecimal = (_hour - hour) * scale
  const minute = Math.floor(minuteDecimal)
  const second = Math.floor((minuteDecimal - minute) * scale)
  return { hour, minute, second }
}

const getNextRoundedTime = date => {
  let hour = date.getHours()
  let minute = date.getMinutes()

  if (minute === 0) minute = 0
  else if (minute <= 15) minute = 15
  else if (minute <= 30) minute = 30
  else if (minute <= 45) minute = 45
  else {
    hour = hour + 1
    minute = 0
  }

  return DateTime.local().set({ hour, minute, second: 0 }).toJSDate() // Js Date 
}

const getDiffInMinutesFromNow = futureDate => {
  if (!futureDate) return null

  const hour = futureDate.split(':')[0]
  if (hour.length === 1) {
    futureDate = `0${futureDate}`
  }

  let futureTime = DateTime.fromFormat(futureDate.trim(), 'hh:mm a')
  if (futureTime.invalidReason) futureTime = DateTime.fromFormat(futureDate.trim(), 'hh:mm a', { locale: 'en' })

  const now = DateTime.local()
  const diffInMinutes = now.diff(futureTime, 'minutes').minutes

  return diffInMinutes
}

const isWithinTimeDifference = (time1, time2, difference) => {
  const entryTime = new Date(time1)
  const exitTime = new Date(time2)
  const diffInMinutes = Math.abs(exitTime - entryTime) / 60000
  return diffInMinutes < difference
}

export {
  today,
  isToday,
  makeHour,
  isTodayFromJsDate,
  getNextRoundedTime,
  isAllDay,
  formatDateObj,
  formatDateMed,
  formatDateMedMed,
  formatDateToJS,
  formatDateISOShort,
  formatMultiDateToMed,
  getExactHour,
  formatDate,
  getTime,
  compareTimes,
  formatTime,
  getCurrentHour,
  formatDateToISO,
  formatDateToMed,
  formatTimeFromJS,
  getUpcomingDates,
  addMinutesToJsDate,
  stringHourToJsDate,
  formatDateISOtoAMPM,
  formatDateToTimeISO,
  formatDateToISOShort,
  formatDateToTimeHour,
  removeTimezoneFromISO,
  formatTimeFromJSArray,
  removeDateFromDateTimeISO,
  getFormateRangeSessionTime,
  decomposeDecimalTime,
  formatDateToOnlyDay,
  entryTimeIsLaterEqualThanExitTime,
  getDiffInMinutesFromNow,
  formatNoTZTimeFromISOtoAMPM,
  isWithinTimeDifference
}