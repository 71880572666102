import React,
{
  useState,
  useCallback,
  useEffect,
  useMemo
} from 'react'

import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import HtmlTooltip from '../HtmlTooltip/HtmlTooltip'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import startOfDay from "date-fns/startOfDay"

import {
  IconButton,
  Button,
  Badge,
  Divider,
  ListItemIcon,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  useMediaQuery,
  useTheme,
  Alert,
  Stack
} from '@mui/material'

import {
  Event as EventIcon,
  InfoOutlined as InfoOutlinedIcon,
  Close as CloseIcon,
  Add as AddIcon,
  DirectionsCar,
  WatchLater,
  Place,
  Notes,
  Error as ErrorIcon
} from '@mui/icons-material'


import { api } from '../../../api/api'
import { Types } from '../../../state/actionTypes'
import {
  setLoading,
  defaultCatch,
  defaultSuccess,
  eval401Error,
  _dispatch as dispatch
} from '../../../state/actions'

import {
  getNumberToWeekDay,
  defaultFunction,
  getParkingReasons
} from '../../utilities/utilities'

import {
  CustomPickersDay
} from '../../utilities/customMaterialUI'

import {
  getTime,
  formatTime,
  getCurrentHour,
  formatTimeFromJS,
  isTodayFromJsDate,
  addMinutesToJsDate,
  formatDateToTimeISO,
  formatDateISOtoAMPM,
  formatDateToISOShort,
  formatMultiDateToMed,
  entryTimeIsLaterEqualThanExitTime,
  formatDateToOnlyDay,
  formatDateMedMed,
  compareTimes,
} from '../../utilities/FormatDate'

import {
  reasonI18n,
  errorCreateReservationI18n,
} from '../../utilities/Dictionary'

import SwipeableCalendar from '../SwipeableCalendar/SwipeableCalendar'
import SuccessBox from '../SuccessBox/SuccessBox'

import SwipeableTimePicker from '../SwipeableTimePicker/SwipeableTimePicker'
import VehiclesModal from '../VehiclesModal/VehiclesModal'

import ModalNotification from '../ModalNotification/ModalNotification'


import {
  EIGHT_AM,
  FIVE_PM,
  MAX_MULTI_DAY_SELECTED_PARKING,
  DEFAULT_OFFSET_MIN_RESERVATION_MINUTES,
  DESK_RESERVATION,
  PARKING_DESK_RESERVATION,
  END_DAY,
  PARKING_RESERVATION,
  INFO,
  SOFT,
  HARD,
  LESSER,
  ERROR,
  PARKING_ASSIGNATION
} from '../../../variables'

import MenuItemDefault from '../MenuItemDefault'

import './ParkingReservation.sass'

const iconSx = { color: '#8C8CA1', fontSize: 15 }
const today = new Date()

function ParkingReservation(props) {

  const {
    dispatch,
    defaultCatch,
    defaultSuccess,
    eval401Error,
    setLoading,
    minReservationTime,
    maxBookingPerPerson,
    parkingReservations,
    parkingAssignations,
    parkingSchedules = [],
    enabledParkingSchedule,
    maxFutureDaysToReserve,
    parkingTotalReservations,
    askForParkingReservationReason,
    askForParkingReservationVehicle,
    askForParkingReservationEntryTime,
    maxSimultaneosParkingReservations,
    enabledMultiplesParkingReservations,
    enabledMultiplesDaysParkingReservations,
    parkingReservationsMultiplesOffset,
    selectedType,
    setSelectedType = defaultFunction,
    setInitDate = defaultFunction

  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const reasons = useMemo(() => getParkingReasons(t), [t])

  const [dateList, setDateList] = useState([today])
  const [calendarDateList, setCalendarDateList] = useState([today])
  const [calendarError, setCalendarError] = useState('')

  const getDefaultEntryTime = useCallback((_date = today) => {
    if (isTodayFromJsDate(_date)) {
      const minutes = minReservationTime + DEFAULT_OFFSET_MIN_RESERVATION_MINUTES
      return getCurrentHour(0, minutes)
    }
    return EIGHT_AM
  }, [minReservationTime])

  const [timePickerValue, setTimePickerValue] = useState([getDefaultEntryTime()])
  const [entry_time, setEntryTime] = useState([getDefaultEntryTime()])
  const [timePickerValueError, setTimePickerValueError] = useState('')

  const getDefaultExitTime = useCallback((_date = today, index = 0) => {
    if (isTodayFromJsDate(_date) && entry_time[index] > FIVE_PM) return END_DAY
    return FIVE_PM
  }, [entry_time])

  const [exitTimePickerValue, setExitTimePickerValue] = useState([getDefaultExitTime()])
  const [exit_time, setExitTime] = useState([getDefaultExitTime()])

  const [activeStep, setActiveStep] = useState('FORM')
  const [successItems, setSuccessItems] = useState(null)
  const [errorItems, setErrorItems] = useState(null)
  const [openTimePicker, setOpenTimePicker] = useState(-1)
  const [openExitTimePicker, setOpenExitTimePicker] = useState(false)

  const [openCalendar, setOpenCalendar] = useState(false)
  const [openVehicles, setOpenVehicles] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [staticVehicles, setStaticVehicles] = useState([])

  const [daysAvailability, setDaysAvailability] = useState([])
  const [hasAssignation, setHasAssignation] = useState(undefined)
  const [hasReservation, setHasReservation] = useState(undefined)

  const [parkingLots, setParkingLots] = useState([])
  const [parking_lot, setParkingLot] = useState(-1)
  const [vehicles, setVehicles] = useState([])
  const [vehicle_id, setVehicleId] = useState(-1)
  const [reason, setReason] = useState(reasons[1])
  const [parkingScheduleDay, setParkingScheduleDay,] = useState({})
  const [multiDay, setMultiDay] = useState(false)
  const [errorDay, setErrorDay] = useState()
  const [multiEntryExitTime, setMultiEntryExitTime] = useState(false)
  const [openMultiEntryExitTimeModal, setOpenMultiEntryExitTimeModal] = useState(false)


  const fetchAvailability = useCallback(data => {

    const {
      index = 0,
      start_date,
      end_date,
      entry_time,
      exit_time,
      prevDaysAvailability = daysAvailability
    } = data;

    const params = {
      start_date,
      end_date,
      entry_time: formatDateToTimeISO(entry_time)
    }

    if (enabledMultiplesParkingReservations) {
      let newExitTime = addMinutesToJsDate(
        exit_time,
        parkingReservationsMultiplesOffset
      )

      if (newExitTime.getDay() === exit_time.getDay()) {
        params.exit_time = formatDateToTimeISO(newExitTime)
      } else {
        params.exit_time = formatDateToTimeISO(END_DAY)
      }
    }

    setLoading(true)
    setErrorDay(undefined)

    api.get('/parking_spaces/availability', { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const { availables = [] } = data
        const { availability = [] } = availables[0]
        setParkingLots(availability)

        if (parking_lot === -1 && dateList.length === 1 && availability.length === 1) {
          setParkingLot(availability[0].parking_lot_id)
        }

        const _daysAvailability = [...prevDaysAvailability]
        _daysAvailability[index] = availability
        setDaysAvailability(_daysAvailability)
      })
      .catch(error => defaultCatch(error, navigate, () => fetchAvailability(data)))
  }, [dateList.length, daysAvailability, defaultCatch, defaultSuccess, enabledMultiplesParkingReservations, navigate, parkingReservationsMultiplesOffset, parking_lot, setLoading])


  const fetchVehicles = useCallback(({ fromModal }) => {
    setLoading(true)
    api.get('/vehicles')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setVehicles(data.vehicles)
        if (data.vehicles.length) {
          setVehicleId(data.vehicles[0].id)
        }
        setStaticVehicles(data.vehicles)
        setOpenVehicles(false)
        if (!fromModal) {
          fetchAvailability({
            start_date: today,
            end_date: today,
            entry_time: entry_time[0],
            exit_time: exit_time[0]
          })
        }
      })
      .catch(error => defaultCatch(error, navigate, () => fetchVehicles({ fromModal })))
      .finally(() => setLoadingModal(false))
  }, [defaultCatch, defaultSuccess, entry_time, exit_time, fetchAvailability, navigate, setLoading])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchVehicles({}), [])

  const getMaxDateParking = useCallback(() => {
    if (!maxFutureDaysToReserve) return undefined
    return DateTime
      .local()
      .plus({ days: maxFutureDaysToReserve })
      .toJSDate()
  }, [maxFutureDaysToReserve])

  const goDeskReservation = useCallback(() => {
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload: {
        open: true,
        message: t('success reservation'),
        severity: 'success',
        autoHideDuration: 2000
      }
    })
    setInitDate(dateList[0])
    setSelectedType(DESK_RESERVATION)
  }, [dateList, dispatch, setInitDate, setSelectedType, t])

  const loadErrorItems = data => {
    const {
      parking_reservations = [],
      messages = []
    } = data

    const errorItems = []
    setErrorItems(errorItems)

    if (
      parking_reservations.length &&
      messages.length
    ) {

      messages.forEach(m => {
        const errorItem = { ...parking_reservations[0] }
        const message = m.split(":")
        errorItem.parking_date = message[0]
        errorItem.error = message[1]
        errorItems.push(errorItem)
      })

      setErrorItems(errorItems)
    }
  }

  const saveReservation = useCallback(() => {
    setLoading(true)
    const payload = {
      parking_reservation: {
        parking_lot_id: parking_lot,
        reason
      },
      dates: dateList.map(formatDateToISOShort)
    }

    if (askForParkingReservationVehicle) {
      payload.parking_reservation.vehicle_id = vehicle_id
    }

    if (multiEntryExitTime && multiDay && entry_time.length) {
      payload.entries = entry_time.map(time => formatDateToTimeISO(time))
    } else {
      payload.parking_reservation.entry_time = formatDateToTimeISO(entry_time[0])
    }

    if (enabledMultiplesParkingReservations) {
      if (enabledMultiplesDaysParkingReservations) {
        if (multiEntryExitTime && multiDay && exit_time.length) {
          payload.exits = exit_time.map(time => formatDateToTimeISO(time))
        } else {
          payload.parking_reservation.exit_time = formatDateToTimeISO(exit_time[0])
        }
      } else {
        let newExitTime = addMinutesToJsDate(
          exit_time[0],
          parkingReservationsMultiplesOffset
        )

        if (newExitTime.getDay() === exit_time[0].getDay()) {
          payload.parking_reservation.exit_time = formatDateToTimeISO(newExitTime)
        } else {
          payload.parking_reservation.exit_time = formatDateToTimeISO(END_DAY)
        }
      }
    }

    api.post('/parking_reservations/multiples', payload)
      .then(defaultSuccess)
      .then(({ data }) => {

        if (!data.success && (data.messages || data.message)) {
          let message;
          if (data.message) message = data.message
          else if (Array.isArray(data.messages)) message = data.messages[0]
          else if (Array.isArray(message)) message = message[0]
          else message = t("An unknown error has occurred, please contact your administrator.")

          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: errorCreateReservationI18n(message),
              severity: 'error',
              autoHideDuration: 6000
            }
          })
          return
        }

        if (selectedType === PARKING_DESK_RESERVATION) goDeskReservation()
        else {
          const { parking_reservations = [] } = data
          loadErrorItems(data)
          setSuccessItems(parking_reservations)
          setActiveStep('SUCCESS')
        }
      })
      .catch(error => defaultCatch(error, navigate, saveReservation))
  }, [askForParkingReservationVehicle, dateList, defaultCatch, defaultSuccess, dispatch, enabledMultiplesDaysParkingReservations, enabledMultiplesParkingReservations, entry_time, exit_time, goDeskReservation, multiDay, multiEntryExitTime, navigate, parkingReservationsMultiplesOffset, parking_lot, reason, selectedType, setLoading, t, vehicle_id])


  const isEnabled = useCallback(() => {
    return (
      parking_lot > -1 &&
      entry_time.length &&
      dateList.length &&
      !hasAssignation &&
      !hasReservation &&
      (!enabledMultiplesParkingReservations || exit_time) &&
      (!askForParkingReservationReason || reason) &&
      (!askForParkingReservationVehicle || vehicle_id > -1)
    )
  }, [parking_lot, entry_time.length, dateList.length, hasAssignation, hasReservation, enabledMultiplesParkingReservations, exit_time, askForParkingReservationReason, reason, askForParkingReservationVehicle, vehicle_id])


  const evalPrevAssignation = useCallback(ISODate => {
    if (parking_lot === -1) return undefined
    return parkingAssignations.find(r => r.reservation_date === ISODate && r.parking_lot_id === parking_lot)
  },
    [parkingAssignations, parking_lot]
  )
  const evalPrevReservation = useCallback(ISODate => {
    if (parking_lot === -1) return undefined
    return parkingReservations.find(r => r.reservation_date === ISODate && r.parking_lot_id === parking_lot)
  }, [parkingReservations, parking_lot]
  )

  const evalPrevUsage = useCallback(date => {
    const ISODate = formatDateToISOShort(date)

    const assignation = evalPrevAssignation(ISODate)
    setHasAssignation(assignation)
    setErrorDay(assignation && ISODate)
    if (assignation) return -1

    const reservation = evalPrevReservation(ISODate)
    setHasReservation(reservation)
    setErrorDay(reservation && ISODate)
    if (reservation) return -1

  }, [evalPrevAssignation, evalPrevReservation])

  useEffect(() => {
    if (!multiDay) evalPrevUsage(dateList[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parking_lot])

  const renderDayParking = useCallback((day, _value, DayComponentProps) => {
    const ISODate = formatDateToISOShort(day)
    const isToday = ISODate === formatDateToISOShort(_value[0])
    const selected = findDate(calendarDateList, day)
    const prevAssignation = evalPrevAssignation(ISODate)
    const prevReservation = evalPrevReservation(ISODate)

    return (
      <Badge
        className={`
          badge-calendar
          ${isToday ? 'today' : ''}
        `}
        key={day.toString()}
        badgeContent={prevAssignation || prevReservation ? '.' : undefined}
      >
        <CustomPickersDay
          {...DayComponentProps}
          disableMargin
          unavailable={ISODate === errorDay}
          selected={selected}
        />
      </Badge>
    );
  }, [calendarDateList, errorDay, evalPrevAssignation, evalPrevReservation])

  const failParkingScheduleDay = useCallback(
    () => enabledParkingSchedule && parkingScheduleDay === undefined,
    [enabledParkingSchedule, parkingScheduleDay]
  )

  const getMessageCalendar = useCallback(() => {
    if (calendarError) return calendarError
    if (failParkingScheduleDay()) return t("This day parking is disabled.")
    if (hasAssignation || hasReservation) return t('this day you already have access to the parking lot, select another date')
    return ''
  }, [calendarError, failParkingScheduleDay, hasAssignation, hasReservation, t])


  const isDisabledCalendar = useCallback(() => {
    const hasSelectedDay = calendarDateList.length
    return (
      calendarError ||
      !hasSelectedDay ||
      (!multiDay && hasAssignation) ||
      (!multiDay && hasReservation) ||
      failParkingScheduleDay()
    )
  }, [multiDay, hasReservation, calendarDateList, failParkingScheduleDay, hasAssignation, calendarError])

  const calendarDayError = useCallback(() => {
    return (
      calendarError ||
      hasAssignation ||
      failParkingScheduleDay()
    )
  }, [failParkingScheduleDay, hasAssignation, calendarError])

  const resetMessageCalendar = useCallback(() => {
    setParkingScheduleDay({})
    setCalendarError('')
    setErrorDay(undefined)
  }, [])

  const onChangeMultiDay = useCallback(event => {
    setMultiDay(event.target.checked)
    setCalendarDateList([today])
    setDateList([today])

    const entry = getDefaultEntryTime(today)
    setEntryTime([entry])
    setTimePickerValue([entry])

    const exit = getDefaultExitTime(today)
    setExitTime([exit])
    setExitTimePickerValue([exit])
    setDaysAvailability([])
    resetMessageCalendar()

    fetchAvailability({
      prevDaysAvailability: [],
      start_date: today,
      end_date: today,
      entry_time: entry,
      exit_time: exit
    })
  }, [fetchAvailability, getDefaultEntryTime, getDefaultExitTime, resetMessageCalendar])

  const onChangeTime = useCallback(index => {
    if (!dateList.length) return
    setOpenTimePicker(index)
  }, [dateList])

  const onChangeExitTime = useCallback(index => {
    if (!entry_time.length) return
    setOpenExitTimePicker(index)
  }, [entry_time])

  const onContinueParking = useCallback(() => {
    setOpenCalendar(false)
    setDateList(calendarDateList)
    setEntryTime(timePickerValue)
    setExitTime(exitTimePickerValue)

    setOpenMultiEntryExitTimeModal(enabledMultiplesDaysParkingReservations && calendarDateList.length > 1)
  }, [calendarDateList, enabledMultiplesDaysParkingReservations, exitTimePickerValue, timePickerValue])

  const getFormateDateParking = useCallback(() => {
    if (dateList.length) return formatMultiDateToMed(dateList, t)
    return t('select')
  }, [dateList, t])

  const getFormateTime = useCallback(time => {
    if (time) return formatTimeFromJS(time)
    return t('select')
  }, [t])

  const updateVehicle = useCallback(async vehicle => {
    const { id } = vehicle
    const data = { vehicle }
    try {
      await api.put(`/vehicle/${id}`, data)
    } catch (error) {
      if (error?.response?.status === 401)
        eval401Error(error, () => updateVehicle(vehicle))
      else if (error.response)
        console.log(error.response.data.errors)
      else
        console.log(error.message)
    }
  }, [eval401Error])

  const createVehicle = useCallback(async vehicle => {
    const data = { vehicle }
    try {
      await api.post(`/vehicle`, data)
    } catch (error) {
      if (error?.response?.status === 401)
        eval401Error(error, () => createVehicle(vehicle))
      else if (error.response)
        console.log(error.response.data.errors)
      else
        console.log(error.message)
    }
  }, [eval401Error])

  const deleteVehicle = useCallback(async vehicle => {
    const { id } = vehicle
    try {
      const resp = await api.delete(`/vehicle/${id}`)
      if (!resp?.data?.success) {
        dispatch({
          type: Types.SET_SNACKBAR_DATA,
          payload: {
            open: true,
            message: resp.data.message,
            severity: 'error',
            autoHideDuration: 2000
          }
        })
      }
    } catch (error) {
      if (error?.response?.status === 401)
        eval401Error(error, () => deleteVehicle(vehicle))
      else if (error.response)
        console.log(error.response.data.errors)
      else
        console.log(error.message)
    }
  }, [dispatch, eval401Error])

  const onSaveVehicles = useCallback((async () => {
    setLoadingModal(true)
    const _vehicles = vehicles.filter(v => v.license_plate.trim())

    for (let v of _vehicles) {
      if (!v.id) await createVehicle(v)
      else if (v.deleted) await deleteVehicle(v)
      else if (v.edited) await updateVehicle(v)
    }

    fetchVehicles({ fromModal: true })
  }), [createVehicle, deleteVehicle, fetchVehicles, updateVehicle, vehicles])

  const addNewVehicle = useCallback(() => {
    const _vehicles = [...vehicles]
    _vehicles.push({ symbol: Symbol(), license_plate: '' })
    setVehicles(_vehicles)
  }, [vehicles])

  const removeVehicle = useCallback((e, vehicle) => {
    vehicle.deleted = true
    setVehicles([...vehicles])
  }, [vehicles])

  const onChangeLicensePlate = useCallback((value, vehicle) => {
    vehicle.license_plate = value
    vehicle.edited = true
    setVehicles([...vehicles])
  }, [vehicles])

  const getParkingSchedules = useCallback(() => {
    return <ul>
      {
        parkingSchedules.map((p, key) =>
          <li key={key}>
            {getNumberToWeekDay(p.week_day, t)} {formatDateISOtoAMPM(p.start_time)} {t('to')} {formatDateISOtoAMPM(p.end_time)}
          </li>
        )
      }
    </ul>
  }, [parkingSchedules, t])

  const getDaysAvailabilityMessage = useCallback(() => {
    return daysAvailability.map((d, index) => {
      const availability = d.map(i => i.total).reduce((a, b) => a + b, 0)
      const day = formatDateToOnlyDay(calendarDateList[index])

      return (
        <Alert key={index} severity={availability ? "info" : "error"} icon={false} >
          {
            availability ?
              t("The selected day has spaces available", { day, availability }) :
              t("The day has no spaces available", { day })
          }
        </Alert>
      )
    })
  }, [t, calendarDateList, daysAvailability])

  const renderMessageParking = useCallback(() => {
    const messageCalender = getMessageCalendar()
    return (
      <Stack direction="column" spacing={2}>
        {messageCalender ?
          <Alert icon={false} severity="error">{messageCalender}</Alert> :
          getDaysAvailabilityMessage()
        }
      </Stack>
    )
  }, [getDaysAvailabilityMessage, getMessageCalendar])

  const onChangeTimePicker = useCallback((time, index = 0) => {
    if (findDate([today], dateList[index])) {
      const defaultMinTime = getDefaultEntryTime(today)
      if (compareTimes(time, defaultMinTime) === LESSER) {
        setTimePickerValueError(
          t('It is not possible to select past hours, please choose a valid schedule.')
        )
      } else setTimePickerValueError('')
    } else setTimePickerValueError('')

    const entry = [...entry_time]
    entry[index] = time
    setTimePickerValue(entry)
  }, [dateList, entry_time, getDefaultEntryTime, t])

  const onChangeExitTimePicker = useCallback((time, index = 0) => {
    const exit = [...exit_time]
    exit[index] = time
    setExitTimePickerValue(exit)
  }, [exit_time])

  const onChangeVehicle = event => {
    const value = event.target.value
    if (value === 'add-another') setOpenVehicles(true)
    else setVehicleId(value)
  }

  const findIndexDate = (dates, date) => {
    const day = date.getDate()
    const month = date.getMonth()
    return dates.findIndex(item => item.getDate() === day && item.getMonth() === month)
  }

  const findDate = (dates, date) => {
    const day = date.getDate()
    const month = date.getMonth()
    return dates.find(item => item.getDate() === day && item.getMonth() === month)
  };

  const onAcceptMultiEntryExitTimeModal = () => {
    setOpenMultiEntryExitTimeModal(false)
    setMultiEntryExitTime(false)
  }

  const onCancelMultiEntryExitTimeModal = () => {
    setOpenMultiEntryExitTimeModal(false)
    setMultiEntryExitTime(true)
  }

  const removeDaySelected = useCallback((index, options = {}) => {
    const {
      type = SOFT
    } = options

    const _calendarDateList = [...calendarDateList]
    const _timePickerValue = [...timePickerValue]
    const _exitTimePickerValue = [...exitTimePickerValue]
    const _daysAvailability = [...daysAvailability]

    _calendarDateList.splice(index, 1)
    _timePickerValue.splice(index, 1)
    _exitTimePickerValue.splice(index, 1)
    _daysAvailability.splice(index, 1)

    setCalendarDateList(_calendarDateList)
    setDaysAvailability(_daysAvailability)
    setTimePickerValue(_timePickerValue)
    setExitTimePickerValue(_exitTimePickerValue)

    if (type === HARD) {
      setDateList(_calendarDateList)
      setEntryTime(_timePickerValue)
      setExitTime(_exitTimePickerValue)

      if (_calendarDateList.length === 0) {
        setParkingLots([])
        setParkingLot(-1)
      }
    }

  }, [calendarDateList, daysAvailability, exitTimePickerValue, timePickerValue])

  const onChangeCalendarMultiDay = useCallback(date => {
    const _calendarDateList = [...calendarDateList]
    const _date = startOfDay(date)
    const ISODate = formatDateToISOShort(date)
    const _entryTimePickerValue = [...timePickerValue]
    const _exitTimePickerValue = [...exitTimePickerValue]
    const index = findIndexDate(_calendarDateList, _date)

    if (
      calendarDayError() &&
      index !== (_calendarDateList.length - 1)
    ) return -1

    if (index >= 0) {
      removeDaySelected(index)
      resetMessageCalendar()
      return -1
    }

    if (_calendarDateList.length > MAX_MULTI_DAY_SELECTED_PARKING) {
      setErrorDay(ISODate)
      setCalendarError(t("Number of allowed reservations exceeded, please select only x days", { days: MAX_MULTI_DAY_SELECTED_PARKING }))
      _calendarDateList.push(_date)
      setCalendarDateList(_calendarDateList)
      return -1
    }

    const maxBooking = maxBookingPerPerson - parkingTotalReservations
    if (_calendarDateList.length >= maxBooking) {
      setErrorDay(ISODate)
      setCalendarError(t("Number of allowed reservations exceeded, please select only x days", { days: maxBooking }))
      _calendarDateList.push(_date)
      setCalendarDateList(_calendarDateList)
      return -1
    }

    if (_calendarDateList.length >= maxSimultaneosParkingReservations) {
      setErrorDay(ISODate)
      setCalendarError(t("Number of allowed reservations exceeded, please select only x days", { days: maxSimultaneosParkingReservations }))
      _calendarDateList.push(_date)
      setCalendarDateList(_calendarDateList)
      return -1
    }

    _calendarDateList.push(_date)
    const lastIndex = _calendarDateList.length - 1

    const entry = getDefaultEntryTime(_date)
    _entryTimePickerValue.push(entry)

    const exit = getDefaultExitTime(_date, lastIndex)
    _exitTimePickerValue.push(exit)

    setCalendarDateList(_calendarDateList)
    setTimePickerValue(_entryTimePickerValue)
    setExitTimePickerValue(_exitTimePickerValue)

    fetchAvailability({
      index: lastIndex,
      start_date: _date,
      end_date: _date,
      entry_time: entry,
      exit_time: exit
    })

    return 0

  }, [calendarDateList, calendarDayError, exitTimePickerValue, fetchAvailability, getDefaultEntryTime, getDefaultExitTime, maxBookingPerPerson, maxSimultaneosParkingReservations, parkingTotalReservations, removeDaySelected, resetMessageCalendar, t, timePickerValue])


  const onChangeCalendarSingleDay = useCallback(date => {
    const _date = startOfDay(date)
    const entry = getDefaultEntryTime(_date)
    const exit = getDefaultExitTime(_date)

    setCalendarDateList([_date])
    setTimePickerValue([entry])
    setExitTimePickerValue([exit])

    if (evalPrevUsage(date) === -1) return -1

    fetchAvailability({
      start_date: _date,
      end_date: _date,
      entry_time: entry,
      exit_time: exit
    })

    return 0
  }, [evalPrevUsage, fetchAvailability, getDefaultEntryTime, getDefaultExitTime])

  const onChangeCalendarDate = useCallback(date => {
    const ISODate = formatDateToISOShort(date)
    let status = 0
    setHasAssignation(undefined)
    setHasReservation(undefined)

    if (multiDay) {
      status = onChangeCalendarMultiDay(date)
      if (status === -1) return
    } else {
      status = onChangeCalendarSingleDay(date)
      if (status === -1) return
    }

    if (enabledParkingSchedule) {
      const week_day = date.getDay()
      const parkingScheduleDay = parkingSchedules.find(p => p.week_day === week_day)
      setParkingScheduleDay(parkingScheduleDay)
      if (!parkingScheduleDay) setErrorDay(ISODate)
    }
  }, [multiDay, onChangeCalendarMultiDay, onChangeCalendarSingleDay, enabledParkingSchedule, parkingSchedules])

  const onContinueTimePicker = useCallback(index => {
    setEntryTime(timePickerValue)

    if (enabledMultiplesParkingReservations) {
      const date = calendarDateList[index]
      const entry_time = timePickerValue[index]
      const exit_time = exitTimePickerValue[index]
      fetchAvailability({ start_date: date, end_date: date, entry_time, exit_time })
    }
    setOpenTimePicker(-1)
  }, [enabledMultiplesParkingReservations, calendarDateList, exitTimePickerValue, fetchAvailability, timePickerValue])

  const onContinueExitTimePicker = useCallback(index => {
    setExitTime(exitTimePickerValue)

    if (enabledMultiplesParkingReservations) {
      const date = calendarDateList[index]
      const entry_time = timePickerValue[index]
      const exit_time = exitTimePickerValue[index]
      fetchAvailability({ start_date: date, end_date: date, entry_time, exit_time })
    }
    setOpenExitTimePicker(-1)
  }, [enabledMultiplesParkingReservations, exitTimePickerValue, calendarDateList, timePickerValue, fetchAvailability])

  const resetTimePicker = useCallback(() => {
    setTimePickerValueError('')
    setTimePickerValue(entry_time)
    setExitTimePickerValue(exit_time)
  }, [entry_time, exit_time])

  const onCloseTimePicker = useCallback(() => {
    setOpenTimePicker(-1)
    setOpenExitTimePicker(-1)
    resetTimePicker()
  }, [resetTimePicker])

  const onCloseCalendar = useCallback(() => {
    setOpenCalendar(false)
    setCalendarDateList(dateList)
    if (!multiDay) evalPrevUsage(dateList[0])
    resetTimePicker()
    resetMessageCalendar()
  }, [dateList, evalPrevUsage, multiDay, resetMessageCalendar, resetTimePicker])

  const getInfoParagraph = useCallback(() => {
    if (!enabledMultiplesParkingReservations || !enabledParkingSchedule) return ''
    const date = dateList[0]

    let week_day = date.getDay()
    if (week_day === 0) week_day = 7
    const parkingScheduleDay = parkingSchedules.find(d => d.week_day === week_day)

    if (!parkingScheduleDay) {
      return (
        <p className='info'>
          <ErrorIcon /> {t("This day parking is disabled.")}
        </p>
      )
    }

    const { start_time, end_time } = parkingScheduleDay
    const payload = {
      a: formatDateISOtoAMPM(start_time),
      b: formatDateISOtoAMPM(end_time)
    }

    return (
      <p className='info'>
        <ErrorIcon /> {t('Parking available between X:XX and X:XX.', payload)}
      </p>
    )

  }, [dateList, enabledMultiplesParkingReservations, enabledParkingSchedule, parkingSchedules, t])

  const renderTimePickerMessage = useCallback(index => {
    return <Stack spacing={2} className='stack-messages'>
      {
        askForParkingReservationEntryTime &&
        enabledMultiplesParkingReservations &&
        entryTimeIsLaterEqualThanExitTime(
          timePickerValue[index],
          exitTimePickerValue[index]
        ) && (
          <Alert key={index} severity="error" icon={false} >
            {t('Check out time must be greater than check in time')}
          </Alert>
        )
      }

      {
        timePickerValueError &&
        <Alert key={index} severity="error" icon={false} >
          {timePickerValueError}
        </Alert>
      }

    </Stack>
  }, [askForParkingReservationEntryTime, enabledMultiplesParkingReservations, timePickerValue, exitTimePickerValue, t, timePickerValueError])

  const disabledTimePicker = useCallback(
    index =>
      timePickerValueError ||
      (
        askForParkingReservationEntryTime &&
        enabledMultiplesParkingReservations &&
        entryTimeIsLaterEqualThanExitTime(
          timePickerValue[index],
          exitTimePickerValue[index]
        )
      ),
    [askForParkingReservationEntryTime, enabledMultiplesParkingReservations, exitTimePickerValue, timePickerValue, timePickerValueError]
  )

  const getEntryExitTimeForm = useCallback((day, index) => {
    return (
      <Stack
        key={index}
        className='entry-exit-time-form'
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        {
          multiEntryExitTime &&
          multiDay &&
          dateList.length > 1 &&
          <label>{formatDateToOnlyDay(day)}</label>
        }
        <Stack direction="column" spacing={4}>
          {askForParkingReservationEntryTime &&
            <Grid item xs={12} md>
              <FormControl fullWidth>
                <InputLabel shrink>
                  {t('entry time')}
                </InputLabel>
                <TextField
                  value={getFormateTime(entry_time[index])}
                  disabled={!dateList.length}
                  onClick={() => onChangeTime(index)}
                  variant="filled"
                  fullWidth
                />
              </FormControl>
            </Grid>
          }
          {askForParkingReservationEntryTime &&
            enabledMultiplesParkingReservations &&
            <Grid item xs={12} md>
              <FormControl fullWidth>
                <InputLabel shrink>
                  {t('exit time')}
                </InputLabel>
                <TextField
                  value={getFormateTime(exit_time[index])}
                  disabled={!entry_time[index]}
                  onClick={() => onChangeExitTime(index)}
                  variant="filled"
                  fullWidth
                />
              </FormControl>
            </Grid>
          }
        </Stack>
        {getInfoParagraph()}
        <SwipeableTimePicker
          showAllTime={false}
          time={timePickerValue[index]}
          open={openTimePicker === index}
          setTime={time => onChangeTimePicker(time, index)}
          setOpen={_ => setOpenTimePicker(index)}
          onContinue={onContinueTimePicker}
          renderMessage={() => renderTimePickerMessage(index)}
          isDisabled={disabledTimePicker(index)}
          onClose={onCloseTimePicker}
        />
        <SwipeableTimePicker
          showAllTime={false}
          time={exitTimePickerValue[index]}
          open={openExitTimePicker === index}
          setTime={time => onChangeExitTimePicker(time, index)}
          setOpen={_ => setOpenExitTimePicker(index)}
          onContinue={onContinueExitTimePicker}
          renderMessage={() => renderTimePickerMessage(index)}
          isDisabled={disabledTimePicker(index)}
          onClose={onCloseTimePicker}
        />
      </Stack>
    )
  }, [askForParkingReservationEntryTime, dateList, disabledTimePicker, enabledMultiplesParkingReservations, entry_time, exitTimePickerValue, exit_time, getFormateTime, getInfoParagraph, multiDay, multiEntryExitTime, onChangeExitTime, onChangeExitTimePicker, onChangeTime, onChangeTimePicker, onCloseTimePicker, onContinueExitTimePicker, onContinueTimePicker, openExitTimePicker, openTimePicker, renderTimePickerMessage, t, timePickerValue])

  const getTimePikers = useCallback(
    () => {
      return (
        <Grid item xs={12}>
          <label
            className="pre-label-form">
            {t('What time would you like to book?')}
          </label>

          <Grid>
            {multiEntryExitTime ?
              dateList.map((day, index) =>
                getEntryExitTimeForm(day, index)) : getEntryExitTimeForm(dateList[0], 0)
            }
          </Grid>
        </Grid>
      )
    }, [multiEntryExitTime, dateList, getEntryExitTimeForm, t])

  const removeDaySelectedFromButton = useCallback(index => {
    removeDaySelected(index, { type: HARD })
    resetMessageCalendar()
  }, [removeDaySelected, resetMessageCalendar])

  const getDaysAvailabilityParkingMessage = useCallback(() => {
    if (parking_lot === -1 || daysAvailability.length === 0) return ''

    const parkingTotals = daysAvailability.map(dayAvailability => {
      const parkingAvailability = dayAvailability.find(parking => parking.parking_lot_id === parking_lot);
      return parkingAvailability ? parkingAvailability.total : 0
    })

    return (
      parkingTotals.map((totalSpaces, index) => {
        const day = formatDateToOnlyDay(calendarDateList[index], { showWeekDay: true })
        const hasSpaces = totalSpaces > 0

        return (
          <Alert
            key={index}
            severity={hasSpaces ? "info" : "error"}
            icon={false}
            action={
              !hasSpaces &&
              <Button
                color="primary"
                size="small"
                onClick={() => removeDaySelectedFromButton(index)}
              >
                {t('Do not reserve this day')}
              </Button>
            }
          >
            {
              hasSpaces ?
                t("The selected day has spaces available", { day, availability: totalSpaces }) :
                t("The day has no spaces available", { day })
            }
          </Alert>
        )
      })
    )
  }, [calendarDateList, daysAvailability, removeDaySelectedFromButton, parking_lot, t])

  const getSingleDayAvailabilityParkingMessage = useCallback(() => {
    if (hasAssignation || hasReservation) {
      return (
        <Alert
          severity="error"
          icon={false}
        >{t('this day you already have access to the parking lot, select another date')}
        </Alert>
      )
    }
    return ''
  }, [hasAssignation, hasReservation, t])

  const getParkingLotMessage = useCallback(() => {
    if (multiDay) return getDaysAvailabilityParkingMessage()
    return getSingleDayAvailabilityParkingMessage()
  }, [getDaysAvailabilityParkingMessage, getSingleDayAvailabilityParkingMessage, multiDay])

  const slideForm = useCallback(() => {
    return (
      <>
        <div className="ParkingReservation div-reservation">
          <h2>{t('parking reservation')}</h2>
          <div className='form-wrapper'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={isLarge ? 8 : 5} alignItems="flex-end">
                <Grid item xs={12}>
                  <label
                    className="pre-label-form">
                    {t('when do you plan to arrive?')}
                    {
                      enabledParkingSchedule &&
                      askForParkingReservationEntryTime &&
                      <HtmlTooltip
                        arrow
                        placement={isLarge ? "right" : "left"}
                        title={t('Available hours of use')}
                        renderMessage={getParkingSchedules}
                      >
                        <IconButton
                          className="item-icon parking-schedules"
                          size="large"
                          edge="start"
                          aria-label="info"
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                      </HtmlTooltip>
                    }
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('date')}
                        </InputLabel>
                        <TextField
                          value={getFormateDateParking()}
                          onClick={() => setOpenCalendar(true)}
                          variant="filled"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {getTimePikers()}

                {parkingLots.length !== 1 &&
                  <Grid item xs={12}>
                    <label
                      className="pre-label-form">
                      {t('where will you park?')}
                    </label>
                    <Stack direction="column" spacing={2}>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="parking-lot-label">
                          {t('parking')}
                        </InputLabel>
                        <Select
                          labelId="parking-lot-label"
                          variant="filled"
                          value={parking_lot}
                          onChange={event => setParkingLot(event.target.value)}
                        >
                          <MenuItemDefault />
                          {parkingLots.map(p =>
                            <MenuItem
                              disabled={!multiDay && p.total === 0}
                              key={p.parking_lot_id}
                              value={p.parking_lot_id}>
                              {multiDay ?
                                p.name :
                                `${p.name} - ${p.total} ${t('available spaces')}`
                              }
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      {getParkingLotMessage()}
                    </Stack>
                  </Grid>
                }
                {askForParkingReservationReason &&
                  <Grid item xs={12}>
                    <label
                      className={`pre-label-form ${!entry_time.length ? 'disabled' : ''}`}>
                      {t('what is your reason for attendance?')}
                    </label>
                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="reason-label">
                        {t('Reason for attendance')}
                      </InputLabel>
                      <Select
                        variant="filled"
                        labelId="reason-label"
                        value={reason}
                        disabled={!entry_time.length}
                        onChange={event => setReason(event.target.value)}
                      >
                        <MenuItemDefault />
                        {reasons.map((r, index) =>
                          <MenuItem
                            key={index}
                            value={r}>
                            {r}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                }

                {
                  askForParkingReservationVehicle &&
                  <Grid item xs={12}>
                    <label
                      className={`pre-label-form ${reason === -1 ? 'disabled' : ''}`}>
                      {t('how will you go?')}
                    </label>
                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="vehicle-label">
                        {t('vehicle')}
                      </InputLabel>
                      <Select
                        variant="filled"
                        labelId="vehicle-label"
                        disabled={reason === -1}
                        value={vehicle_id}
                        onChange={onChangeVehicle}
                      >
                        <MenuItemDefault />
                        {staticVehicles.map(v =>
                          <MenuItem
                            key={v.id}
                            value={v.id}>
                            {v.license_plate}
                          </MenuItem>
                        )}
                        <Divider />
                        <MenuItem
                          value="add-another"
                        >
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          {t('add another vehicle')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                }
              </Grid>
            </LocalizationProvider>
          </div>
          <div className='new-reservation-footer'>
            <Button
              fullWidth={!isLarge}
              variant="outlined"
              disabled={!isEnabled()}
              onClick={saveReservation} >
              {isLarge ?
                t('ACCEPT RESERVATION') :
                t('ACCEPT')
              }
            </Button>
          </div>
        </div>
        <SwipeableCalendar
          renderMessage={renderMessageParking}
          minDate={today}
          maxDate={getMaxDateParking()}
          date={calendarDateList}
          open={openCalendar}
          isDisabled={isDisabledCalendar()}
          onChange={onChangeCalendarDate}
          setOpen={setOpenCalendar}
          renderDay={renderDayParking}
          onContinue={onContinueParking}
          onClose={onCloseCalendar}
          onChangeMultiDay={onChangeMultiDay}
          multiDay={multiDay}
          enabledMultiDay={enabledMultiplesDaysParkingReservations}
        />
        <VehiclesModal
          loading={loadingModal}
          open={openVehicles}
          vehicles={vehicles}
          setOpen={setOpenVehicles}
          removeVehicle={removeVehicle}
          onSaveVehicles={onSaveVehicles}
          onChangeLicensePlate={onChangeLicensePlate}
          addNewVehicle={addNewVehicle}
        />
        <ModalNotification
          open={openMultiEntryExitTimeModal}
          title={t('Do you want your reservations to have the same time selection?')}
          type={INFO}
          acceptLabel={t('Yes')}
          cancelLabel={t('No')}
          onAccept={onAcceptMultiEntryExitTimeModal}
          onCancel={onCancelMultiEntryExitTimeModal}
        />
      </>
    )
  }, [
    t,
    isLarge,
    enabledParkingSchedule,
    askForParkingReservationEntryTime,
    getParkingSchedules,
    getFormateDateParking,
    getTimePikers,
    parkingLots,
    parking_lot,
    getParkingLotMessage,
    askForParkingReservationReason,
    entry_time,
    reason,
    reasons,
    askForParkingReservationVehicle,
    vehicle_id,
    staticVehicles,
    isEnabled,
    saveReservation,
    renderMessageParking,
    getMaxDateParking,
    calendarDateList,
    openCalendar,
    isDisabledCalendar,
    onChangeCalendarDate,
    renderDayParking,
    onContinueParking,
    onCloseCalendar,
    onChangeMultiDay,
    multiDay,
    enabledMultiplesDaysParkingReservations,
    loadingModal,
    openVehicles,
    vehicles,
    removeVehicle,
    onSaveVehicles,
    onChangeLicensePlate,
    addNewVehicle,
    openMultiEntryExitTimeModal
  ])

  const getParkingTime = useCallback(reservation => {
    const time = enabledMultiplesParkingReservations ?
      getTime(reservation) :
      formatTime(reservation.entry_time)

    return (
      <li>
        <WatchLater
          sx={iconSx} />
        <span>{time}</span>
      </li>
    )
  }, [enabledMultiplesParkingReservations])

  const slideSuccessList = useCallback((reservation, { type = INFO } = {}) => {
    return (
      <ul className="detail-list">
        <li><span style={{ marginLeft: 0 }}>{t('parking')}</span></li>
        <li><EventIcon sx={iconSx} /><span>{formatDateMedMed(reservation.parking_date)}</span> </li>
        <li><Place sx={iconSx} /><span>{`${reservation.parking_space_label}, ${reservation?.parking_lot?.name}`}</span></li>
        {askForParkingReservationVehicle && <li><DirectionsCar sx={iconSx} width={10} /><span>{reservation?.vehicle?.license_plate}</span></li>}
        {askForParkingReservationEntryTime && type === INFO && getParkingTime(reservation)}
        {askForParkingReservationReason && <li><Notes sx={iconSx} /> <span>{reasonI18n(reservation.reason)}</span></li>}
      </ul>
    )
  }, [askForParkingReservationEntryTime, askForParkingReservationReason, askForParkingReservationVehicle, getParkingTime, t])

  const slideSuccess = useCallback(() => {
    return (
      <SuccessBox>
        {successItems.map((reservation, index) =>
          <div key={index} className="detail-list-wrapper">
            {slideSuccessList(reservation)}
          </div>
        )}

        {errorItems.map((reservation, index) =>
          <div key={index} className="detail-list-wrapper  detail-list-wrapper-erros">
            <Stack direction="row" spacing={1} className='header-detail-list'>
              <CloseIcon />
              <label>
                {t('The reservation could not be created')}
              </label>
            </Stack>
            {slideSuccessList(reservation, { type: ERROR })}
            {
              reservation.error &&
              <Alert severity="error" icon={false} >
                {reservation.error}
              </Alert>
            }
          </div>
        )}
      </SuccessBox>
    )
  }, [errorItems, slideSuccessList, successItems, t])

  const getSlide = useCallback(() => {
    if (activeStep === 'FORM') return slideForm()
    return slideSuccess()
  }, [activeStep, slideForm, slideSuccess])

  return getSlide()
}

const getOnlyParkingReservations = items => {
  return items.filter(i => i.type === PARKING_RESERVATION)
}

const getOnlyParkingAssignations = items => {
  return items.filter(i => i.type === PARKING_ASSIGNATION)
}


const mapStateToProps = state => {
  return {
    parkingTotalReservations: state.parking.total,
    parkingSchedules: state.profile.company.parking_schedules,
    minReservationTime: state.profile.company.min_reservation_time,
    enabledParkingSchedule: state.profile.enabled_parking_schedule,
    maxBookingPerPerson: state.profile.company.max_bookings_per_person,
    maxFutureDaysToReserve: state.profile.company.max_future_days_to_reserve,
    askForParkingReservationReason: state.profile.company.ask_for_parking_reservation_reason,
    parkingReservations: getOnlyParkingReservations(state.general.reservationsAndAssignations),
    parkingAssignations: getOnlyParkingAssignations(state.general.reservationsAndAssignations),
    askForParkingReservationVehicle: state.profile.company.ask_for_parking_reservation_vehicle,
    maxSimultaneosParkingReservations: state.profile.company.max_simultaneos_parking_reservations,
    parkingReservationsMultiplesOffset: state.profile.company.parking_reservations_multiples_offset,
    askForParkingReservationEntryTime: state.profile.company.ask_for_parking_reservation_entry_time,
    enabledMultiplesParkingReservations: state.profile.company.enabled_multiples_parking_reservations,
    enabledMultiplesDaysParkingReservations: state.profile.company.enabled_multiples_parking_days_reservations,
  }
}

const mapDispatchToProps = {
  dispatch,
  eval401Error,
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingReservation)

