import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Chip
} from '@mui/material'

import {
  West as WestIcon,
  ExpandMore as ExpandMoreIcon,
  LocationCity as LocationCityIcon,
  Layers as FloorIcon,
  Person as OccupancyIcon,
  Close as CloseIcon,
  Info,
} from '@mui/icons-material'

import Building from '../Svgs/Building'
import MenuItemDefault from '../MenuItemDefault'
import { autoSetPlace, defaultFunction, getAmenitieImage } from '../../utilities/utilities'

import { useTranslation } from 'react-i18next'

import './AddFiltersModal.sass'
import Counter from '../Counter/Counter'
import { connect } from 'react-redux'

const AddFiltersModal = props => {
  const {
    open = false,
    rooms = {},
    handleClose = defaultFunction,
    sites = [],
    handleApplyFilters = defaultFunction,
  } = props

  const { t } = useTranslation()
  const [site, setSite] = useState(-1)
  const [building, setBuilding] = useState(-1)
  const [floor, setFloor] = useState(-1)
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState([])
  const [capacity, setCapacity] = useState(1)
  const [amenities, setAmenities] = useState([])
  const [roomsCapacity, setRoomsCapacity] = useState(1)

  useEffect(() => {
    autoSetPlace(sites, {
      setBuildings,
      setBuilding,
      setFloors,
      setFloor
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeSite = useCallback(event => {
    const site = event.target.value
    const buildings = sites.find(s => s.id === site).buildings || []
    setSite(site)
    setBuildings(buildings)

    if (buildings.length === 1) {
      const buildingItem = buildings[0]
      setBuilding(buildingItem.id)
      setFloors(buildingItem.floors)

      if (buildingItem.floors.length === 1) {
        const floorItem = buildingItem.floors[0]
        setFloor(floorItem.id)
      }
    }

  }, [sites])

  const onChangeBuilding = useCallback(event => {
    const building = event.target.value
    const floors = buildings.find(b => b.id === building).floors || []
    setBuilding(building)
    setFloors(floors)

    if (floors.length === 1) {
      const floorItem = floors[0]
      setFloor(floorItem.id)
    }

  }, [buildings])

  const onChangeFloor = useCallback(event => {
    const floor = event.target.value
    setFloor(floor)
  }, [])


  useEffect(() => {
    const roomsCapacityList = []
    if (floor !== -1) {
      const prefilteredRooms = rooms.filter((room) => room.floor?.id === floor)
      prefilteredRooms?.forEach((room) => {
        if (!roomsCapacityList.includes(room.capacity) && room?.capacity !== 0)
          roomsCapacityList.push(room.capacity)
      })
    } else {
      rooms?.forEach((room) => {
        if (!roomsCapacityList.includes(room.capacity) && room?.capacity !== 0)
          roomsCapacityList.push(room.capacity)
      })
    }
    roomsCapacityList.sort((a, b) => a - b)
    setRoomsCapacity(roomsCapacityList)
  }, [rooms, floor])

  const getAllAmenities = numberOfAmenities => {
    let amenitiesComponents = []
    for (let i = 0; i < numberOfAmenities; i++) {
      const amenityIndex = i
      const isSelected = amenities.includes(amenityIndex);
      amenitiesComponents.push(
        <Chip
          key={i}
          variant={isSelected ? 'filled' : 'outlined'}
          icon={getAmenitieImage(i, t)}
          label={`${getAmenitieImage(i, t).props.title}`}
          className='amenity-chip'
          onClick={() => handleChangeAmenities(amenityIndex)}
        />
      )
    }
    return amenitiesComponents
  }

  const handleChangeCapacity = (capacity) => {
    setCapacity(capacity)
  }

  const handleChangeAmenities = (amenity) => {
    setAmenities(prevAmenities => {
      if (prevAmenities.includes(amenity)) {
        return prevAmenities.filter(a => a !== amenity)
      }
      return [...prevAmenities, amenity]

    })
  }

  const onApplyFilters = useCallback(() => {
    handleApplyFilters({
      site,
      building,
      floor,
      capacity,
      amenities,
      filtered: true
    })
  }, [site, building, floor, capacity, amenities, handleApplyFilters])

  const clearFilters = useCallback(() => {
    const site = -1
    const building = -1
    const floor = -1
    const capacity = 1
    const amenities = []

    setSite(site)
    setBuilding(building)
    setFloor(floor)
    setCapacity(capacity)
    setAmenities(amenities)

    handleApplyFilters({
      site,
      building,
      floor,
      capacity,
      amenities,
      filtered: false
    })
  }, [handleApplyFilters])

  const maxRoomCapacity = useMemo(() => roomsCapacity.length ? Math.max(...roomsCapacity) : 0, [roomsCapacity])

  return (
    <Dialog
      className="dialog-detail add-filters"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent className="add-filters-content">
        <Stack>
          <Box className='add-filters-header'>
            <IconButton
              onClick={handleClose}
              className='back-button'
            >
              <WestIcon />
            </IconButton>
            <h1>{t('Add filter')}</h1>
          </Box>
          <Accordion
            elevation={0}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="location-filters-content"
              className="location-filters-header"
            >
              {t('Location filter')}
            </AccordionSummary>
            <AccordionDetails>
              <Stack rowGap={'24px'}>
                <FormControl fullWidth>
                  <Box className='input-label'>
                    <LocationCityIcon fontSize='sm' />
                    {t('site')}
                  </Box>
                  <Select
                    variant="filled"
                    labelId="site-label"
                    value={site}
                    onChange={onChangeSite}
                    placeholder={t('select')}
                  >
                    <MenuItemDefault />
                    {sites?.map(s =>
                      <MenuItem
                        key={s.id}
                        value={s.id}>
                        {s.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <Box className='input-label'>
                    <Building width={'12px'} />
                    {t('building')}
                  </Box>
                  <Select
                    disabled={site === -1}
                    variant="filled"
                    labelId="building-label"
                    value={building}
                    onChange={onChangeBuilding}
                    placeholder={t('select')}
                  >
                    <MenuItemDefault />
                    {buildings.map(b =>
                      <MenuItem
                        key={b.id}
                        value={b.id}>
                        {b.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <Box className='input-label'>
                    <FloorIcon fontSize='sm' />
                    {t('floor')}
                  </Box>
                  <Select
                    disabled={building === -1}
                    variant="filled"
                    labelId="floor-label"
                    value={floor}
                    onChange={onChangeFloor}
                    placeholder={t('select')}
                  >
                    <MenuItemDefault />
                    {floors?.map(f =>
                      <MenuItem
                        key={f.id}
                        value={f.id}>
                        {f.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl fullWidth className='occupancy-capacity-filter'>
                  <Box className='input-label'>
                    <OccupancyIcon fontSize='sm' />
                    {t('Occupancy capacity')}
                  </Box>
                  <Counter
                    initialValue={capacity || 1}
                    min={0}
                    max={999}
                    onChange={handleChangeCapacity}
                  />
                </FormControl>

                <Box className={`occupancy-capacity-message ${capacity >= maxRoomCapacity ? 'max-capacity-reached' : ''}`}>
                  <Info />
                  {t('The room with the largest capacity is for N people', { maxRoomCapacity })}
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion
            elevation={0}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="amenities-filters-content"
              className="amenities-filters"
            >
              {t('Amenities filter')}
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="row" flexWrap="wrap" gap={'8px'}>
                {getAllAmenities(9)}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </DialogContent>
      <DialogActions className='add-filter-actions'>
        <Button
          variant='outlined'
          startIcon={<CloseIcon />}
          onClick={clearFilters}
        >
          {t('Clear filters')}
        </Button>
        <Button
          disabled={site === -1 && capacity === 0 && amenities.length === 0}
          variant='contained'
          onClick={onApplyFilters}
        >
          {t('Apply filters')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    sites: state.workplace.sitesRooms,
  }
}


export default connect(mapStateToProps, null)(AddFiltersModal)