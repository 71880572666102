import React, { useCallback, useState } from 'react'
import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
  Button,
  DialogActions,
  DialogTitle
} from '@mui/material'

import {
  Close as CloseIcon,
  West as WestIcon
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'

import './ScheduleSelector.sass'
import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector'
import { Dot } from '../Svgs'

const ScheduleSelector = props => {
  const {
    open,
    isToday = false,
    handleClose,
    prevReservations,
    onContinue,
    isDateAvailable,
    handleBack,
    workStartTime,
    workEndTime
  } = props

  const { t } = useTranslation()
  const [hasCollision, setHasCollision] = useState(false)
  const [entryTime, setEntryTime] = useState()
  const [exitTime, setExitTime] = useState()

  const onChange = useCallback(params => {
    const { entry_time, exit_time, hasCollision } = params
    setHasCollision(hasCollision)

    if (props.onChange) {
      props.onChange(params)
      return
    }

    setEntryTime(entry_time)
    setExitTime(exit_time)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      className="dialog-detail schedule-selector"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    // fullScreen
    >
      <DialogTitle>
        <Box className='schedule-selector-header'>
          {isDateAvailable ?
            <>
              <h2>{t('Select the time you would like to book')}</h2>
              <IconButton onClick={handleClose} >
                <CloseIcon />
              </IconButton>
            </>
            :
            <>
              <IconButton onClick={handleBack} >
                <WestIcon />
              </IconButton>
              <h2>{t('View who booked the room')}</h2>
            </>
          }
        </Box>
      </DialogTitle>
      <DialogContent className="dialog-schedule-selector-content">
        <Box className='schedule-selector-legend'>
          <Dot />
          {t('Unavailable Hours')}
        </Box>
        <TimeRangeSelector
          onChange={onChange}
          prevReservations={prevReservations}
          availableDate={isDateAvailable}
          entryTime={props.entryTime ? props.entryTime : entryTime}
          exitTime={props.exitTime ? props.exitTime : exitTime}
          isToday={isToday}
          workStartTime={workStartTime}
          workEndTime={workEndTime}
        />
      </DialogContent>
      {isDateAvailable &&
        <DialogActions>
          <Button
            disabled={hasCollision}
            className='book-room-button'
            variant='contained'
            onClick={() => onContinue({ entryTime, exitTime })}
          >
            {t('save')}
          </Button>
        </DialogActions>}
    </Dialog>
  )
}

export default ScheduleSelector