import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Layout2 from '../../common/components/LayoutCreate/LayoutCreate'
import SwipeableCalendar from '../../common/components/SwipeableCalendar/SwipeableCalendar'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useNavigate } from 'react-router-dom'
import { api } from '../../api/api'
import { Types } from '../../state/actionTypes'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import {
  Button,
  Step,
  StepLabel,
  Stack,
  Divider,
  TextField,
  Stepper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  useMediaQuery,
  useTheme,
  Tab
} from '@mui/material'

import {
  LocationOn as LocationOnIcon,
  LocationCity as LocationCityIcon,
  MultipleStop as MultipleStopIcon,
  FileDownload as FileDownloadIcon,
  CheckCircle as CheckCircleIcon,
  WatchLater as WatchLaterIcon,
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  Event as EventIcon,
  Notes as NotesIcon,
  Email as EmailIcon,
  Pin as PinIcon,
} from '@mui/icons-material'


import {
  formatDate,
  formatDateToMed,
  formatDateMedMed,
  formatDateToTimeISO,
  formatDateToISOShort,
  formatTimeFromJS,
  removeTimezoneFromISO,
  formatTime
} from '../../common/utilities/FormatDate'

import {
  getWorkplaceReasons,
  sortItems,
  getFullNameGuest
} from '../../common/utilities/utilities'


import { REGEX_EMAIL } from '../../variables'

import '../NewReservationPage/NewReservation.sass'
import './NewVisitor.sass'
import SwipeableTimePicker from '../../common/components/SwipeableTimePicker/SwipeableTimePicker'


import {
  _dispatch as dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch,
} from '../../state/actions'

import {
  EIGHT_AM,
  IMPORT,
  VISIT
} from '../../variables'
import DragAndDrop from '../../common/components/DragAndDrop/DragAndDrop'

const iconSx = { color: '#8C8CA1', fontSize: 15 }
const today = new Date()
const steps = [0, 1, 2]

const stackProps = {
  alignItems: "center",
  direction: "row",
  spacing: 1
}

function NewVisitor(props) {
  const {
    dispatch,
    setLoading,
    defaultSuccess,
    defaultCatch,
    company,
  } = props

  const { t } = useTranslation()

  const defaultMessageFileError = t(
    `The file format is incorrect.
    Please download the template and
    place the information based on
    the template`
  )

  const reasons = getWorkplaceReasons(t)
  const navigate = useNavigate()
  const inputDateRef = useRef()
  const inputHourRef = useRef()
  const selectSiteRef = useRef()

  const [activeStep, setActiveStep] = useState(0)
  const [successItem, setSuccessItem] = useState(null)
  const [openTimePicker, setOpenTimePicker] = useState(false)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [calendarDate, setCalendarDate] = useState(null)
  const [timePickerValue, setTimePickerValue] = useState(EIGHT_AM)

  const [date, setDate] = useState(null)
  const [entry_time, setEntryTime] = useState(EIGHT_AM)
  const [identification, setIdentification] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [comesFrom, setComesFrom] = useState('')
  const [emailError, setEmailError] = useState('')
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState({})
  const [site, setSite] = useState(-1)
  const [sites, setSites] = useState([])
  const [building, setBuilding] = useState(-1)
  const [buildings, setBuildings] = useState([])
  const [reason, setReason] = useState(reasons[0])
  const [tab, setTab] = useState(VISIT)
  const [file, setFile] = useState()
  const [invitations, setInvitations] = useState([])

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const evalEmail = useCallback(() => {
    let error = ''

    if (
      email &&
      !REGEX_EMAIL.test(email)
    ) error = t('invalid email')

    setEmailError(error)
    return error
  }, [email, t])

  const continueSlide1 = useCallback(() => {
    return (
      name &&
      lastname &&
      email &&
      comesFrom &&
      !emailError
    )
  }, [comesFrom, email, lastname, name, emailError])

  const globalErrorFileMessage = useCallback((message = defaultMessageFileError) => {
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload: {
        open: true,
        message,
        severity: 'error',
        autoHideDuration: 6000
      }
    })
  }, [defaultMessageFileError, dispatch])

  const showInvalidMessage = useCallback(({ name, value, required, index }) => {
    console.log({ name, value, required, index })
    globalErrorFileMessage(t(
      "The file format is incorrect, The x property in the y row is invalid.",
      { name, index: index + 2 }
    ))
  }, [globalErrorFileMessage, t])

  const invalidEmailField = useCallback(({ name, value, required, index }) => {
    let invalid = ((required && !value) || typeof value !== 'string')
    invalid = !REGEX_EMAIL.test(value)
    if (invalid) showInvalidMessage({ name, value, required, index })
    return invalid
  }, [showInvalidMessage])

  const invalidStringField = useCallback(({ name, value, required, index }) => {
    const invalid = ((required && !value) || typeof value !== 'string')
    if (invalid) showInvalidMessage({ name, value, required, index })
    return invalid
  }, [showInvalidMessage])

  const invalidNumberField = useCallback(({ name, value, required, index }) => {
    const invalid = ((required && !value) || typeof value !== 'number')
    if (invalid) showInvalidMessage({ name, value, required, index })
    return invalid
  }, [showInvalidMessage])


  const parseInvitations = useCallback((data = []) => {
    if (data.length === 0) {
      globalErrorFileMessage(t('Empty file'))
      return []
    }

    const _data = []
    const allIsValid = data.every((d, index) => {
      const {
        identification,
        first_name,
        email,
        last_name_one,
        last_name_two,
        comes_from,
      } = d

      const _identification = Number(identification)

      if (invalidStringField({ name: 'first_name', value: first_name, required: true, index })) return false;
      if (invalidStringField({ name: 'last_name_one', value: last_name_one, required: true, index })) return false;
      if (invalidStringField({ name: 'last_name_two', value: last_name_two, required: false, index })) return false;
      if (invalidEmailField({ name: 'email', value: email, required: true, index })) return false;
      if (invalidNumberField({ name: 'identification', value: _identification, required: true, index })) return false;
      if (invalidStringField({ name: 'comes_from', value: comes_from, required: true, index })) return false;

      _data.push({
        first_name,
        last_name_one,
        last_name_two,
        email,
        identification,
        invitation_date: formatDateToISOShort(date),
        invitation_time: formatDateToTimeISO(entry_time),
        comes_from,
        building_id: building
      })

      return true;
    })

    if (allIsValid) return _data
    return []
  }, [globalErrorFileMessage, t, invalidStringField, invalidEmailField, invalidNumberField, date, entry_time, building])

  const onChangeFile = useCallback(file => {
    setFile(file)
    if (!file) {
      setInvitations([])
      return
    }
    const url = URL.createObjectURL(file)
    setLoading(true)
    Papa.parse(
      url,
      {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: result => {
          setLoading(false)
          setInvitations(parseInvitations(result.data))
        }
      }
    )
  }, [parseInvitations, setLoading])

  const fetchCompanies = useCallback(() => {
    setLoading(true)
    api.get('/companies')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const companies = sortItems(data.companies)
        setCompanies(companies)
      })
      .catch(error => defaultCatch(error, navigate, fetchCompanies))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  const fetchSites = useCallback(() => {
    setLoading(true)
    api.get('/workplace/sites')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const sites = sortItems(data.sites)
        setSites(sites)
      })
      .catch(error => defaultCatch(error, navigate, fetchSites))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  const fetchBuilding = useCallback(site => {
    setLoading(true)
    api.get(`/workplace/buildings/${site}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const buildings = sortItems(data.buildings)
        setBuildings(buildings)
      })
      .catch(error => defaultCatch(error, navigate, () => fetchBuilding(site)))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  useEffect(() => {
    fetchSites()
    fetchCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getPlaceByID = useCallback(item => {
    const { site, building } = item
    const nameSite = sites.find(s => s.id === site).name
    const nameBuilding = buildings.find(b => b.id === building).name

    return `${nameSite}, ${nameBuilding}`
  }, [buildings, sites])

  const saveInvitation = useCallback(() => {
    setLoading(true)

    const payload = {
      invitation: {
        first_name: name,
        last_name_one: lastname,
        email,
        reason,
        identification,
        invitation_date: formatDateToISOShort(date),
        invitation_time: formatDateToTimeISO(entry_time),
        comes_from: comesFrom,
        building_id: building,
        company_id: selectedCompany.id ? selectedCompany.id : company.id
      }
    }

    api.post('/invitations', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success && data.message) {
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: data.message,
              severity: 'error',
              autoHideDuration: 6000
            }
          })
        } else {
          data.invitation.site = site
          data.invitation.building = building
          setSuccessItem(data.invitation)
          setActiveStep(2)
        }
      })
      .catch(error => defaultCatch(error, navigate, saveInvitation))
  }, [building, comesFrom, date, defaultCatch, defaultSuccess, dispatch, email, entry_time, identification, lastname, name, navigate, reason, setLoading, site, selectedCompany, company.id])

  const saveMultiInvitations = useCallback(() => {
    setLoading(true)
    const payload = { invitations, invitation: {} }

    api.post('/invitations/batch', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success && data.message) {
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: data.message,
              severity: 'error',
              autoHideDuration: 6000
            }
          })
        } else {
          setActiveStep(2)
        }
      })
      .catch(error => defaultCatch(error, navigate, saveMultiInvitations))
  }, [setLoading, invitations, defaultSuccess, dispatch, defaultCatch, navigate])

  const isEnabledSave = useCallback(() => {
    return (
      date &&
      entry_time &&
      site > -1 &&
      building > -1
    )
  }, [building, date, entry_time, site])

  const getMinTime = useCallback(() => {
    if (!date) return
    const _date = formatDate(date)
    const _today = formatDate(today)
    if (_date === _today) return today
  }, [date])

  const defaultMenuItem = useCallback((label = t('select')) => {
    return (
      <MenuItem value={"-1"} disabled>
        <em>{label}</em>
      </MenuItem>
    )
  }, [t])

  const onChangeDate = useCallback(() => {
    setOpenCalendar(true)
  }, [])

  const onChangeTime = useCallback(() => {
    if (!date) return
    setOpenTimePicker(true)
  }, [date])

  const clearWorkplaceReservation = () => {
    setDate(null)
    setEntryTime(EIGHT_AM)
    setSite(-1)
    setBuilding(-1)
  }

  const handleBack = () => {
    if (activeStep - 1 === 0) {
      clearWorkplaceReservation()
    }
    setActiveStep(activeStep => activeStep - 1)
  }

  const onContinue = useCallback(() => {
    setOpenCalendar(false)
    setDate(calendarDate)
    setEntryTime(EIGHT_AM)
    setSite(-1)
    setBuilding(-1)
  }, [calendarDate])

  const onCloseCalendar = useCallback(() => {
    setOpenCalendar(false)
    setCalendarDate(date)
  }, [date])

  const onContinueTimePicker = useCallback(() => {
    setEntryTime(timePickerValue)
    setSite(-1)
    setBuilding(-1)
    setOpenTimePicker(false)
  }, [timePickerValue])

  const onCloseTimePicker = useCallback(() => {
    setOpenTimePicker(false)
    setTimePickerValue(entry_time)
  }, [entry_time])

  const getFormateDate = useCallback(() => {
    if (date) return formatDateToMed(date)
    return t('select')
  }, [date, t])

  const getFormateTime = useCallback(() => {
    if (entry_time) return formatTimeFromJS(entry_time)
    return t('select')
  }, [entry_time, t])

  const horizontalSteeper = useCallback(() => {
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          {(isLarge && activeStep === 1) && (
            <Button
              color="inherit"
              onClick={handleBack}
              startIcon={
                <ArrowBackIcon className="back-steeper" />
              }
            >
              {t('back')}
            </Button>
          )}
        </Grid>
        <Grid item>
          <Stepper activeStep={activeStep}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs>
        </Grid>
      </Grid>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, isLarge])

  const onChangeSite = useCallback(event => {
    const site = event.target.value
    setSite(site)
    setBuilding(-1)
    fetchBuilding(site)
  }, [fetchBuilding])

  const onChangeBuilding = event => {
    const building = event.target.value
    setBuilding(building)
  }

  const onChangeReason = event => {
    const reason = event.target.value
    setReason(reason)
  }

  const onChangeEmail = useCallback(event => {
    setEmail(event.target.value)
    setEmailError('')
  }, [])

  const onChangeCompany = useCallback(event => {
    const company = event.target.value
    setSelectedCompany(companies.find(c => c.id === company))
  }, [companies])

  const getVisitForm = useCallback(() => {
    return (
      <>
        <div className='form-wrapper workplace'>
          <Stack spacing={2} direction="column">
            <Stack {...stackProps}>
              <PinIcon className='visitor-icon' />
              <label className='visitor-label'>
                {t('identification num.')}
              </label>
              <TextField
                className='visitor-input'
                variant="filled"
                value={identification}
                onChange={e => setIdentification(e.target.value)}
                fullWidth={!isLarge}
                placeholder={t('Required')}
                hiddenLabel
              />
            </Stack>
            <Stack {...stackProps}>
              <PersonIcon className='visitor-icon' />
              <label className='visitor-label'>
                {t('name')}
              </label>
              <TextField
                className='visitor-input'
                variant="filled"
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth={!isLarge}
                placeholder={t('Required')}
                hiddenLabel
              />
            </Stack>
            <Stack {...stackProps}>
              <PersonIcon className='visitor-icon' />
              <label className='visitor-label'>
                {t('last name')}
              </label>
              <TextField
                className='visitor-input'
                variant="filled"
                value={lastname}
                onChange={e => setLastname(e.target.value)}
                fullWidth={!isLarge}
                placeholder={t('Required')}
                hiddenLabel
              />
            </Stack>
            <Stack {...stackProps}>
              <EmailIcon className='visitor-icon' />
              <label className='visitor-label'>
                {t('email')}
              </label>
              <TextField
                className='visitor-input'
                variant="filled"
                value={email}
                error={!!emailError}
                helperText={emailError}
                onChange={onChangeEmail}
                onBlur={() => evalEmail()}
                fullWidth={!isLarge}
                placeholder={t('Required')}
                hiddenLabel
              />
            </Stack>
            <Stack {...stackProps}>
              <MultipleStopIcon className='visitor-icon' />
              <label className='visitor-label'>
                {t('comes from')}
              </label>
              <TextField
                className='visitor-input'
                variant="filled"
                value={comesFrom}
                onChange={e => setComesFrom(e.target.value)}
                fullWidth={!isLarge}
                placeholder={t('Required')}
                hiddenLabel
              />
            </Stack>
            <Stack {...stackProps}>
              <LocationOnIcon className='visitor-icon' />
              <label className='visitor-label'>
                {t('goes to')}
              </label>
              <FormControl className='company-selector'>
                <Select
                  variant="filled"
                  labelId="company-label"
                  value={selectedCompany.id ? selectedCompany.id : "-1"}
                  onChange={onChangeCompany}
                >
                  {defaultMenuItem(t('Required'))}
                  {companies.map(c =>
                    <MenuItem
                      key={c.id}
                      value={c.id}>
                      {c.name}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </div>
        <div className='new-reservation-footer'>
          <Button
            fullWidth={!isLarge}
            variant="outlined"
            disabled={!continueSlide1()}
            onClick={() => setActiveStep(1)} >
            {t('CONTINUE')}
          </Button>
        </div>
      </>
    )
  }, [comesFrom, continueSlide1, email, emailError, evalEmail, identification, isLarge, lastname, name, t, companies, selectedCompany, defaultMenuItem, onChangeCompany, onChangeEmail])

  const disabledSaveMultiInvitation = useCallback(() => {
    return (
      invitations.length === 0 ||
      building === -1
    )
  }, [building, invitations.length])

  const getImportForm = useCallback(() => {
    return (
      <Stack spacing={4}>
        <Stack spacing={6} direction={{ xs: 'column', md: 'row' }}>
          <div className='import-info'>
            {t("Considerations of the file to import")}
            <ul>
              <li>{t("Allowed format:")} CSV (.csv).</li>
              <li>{t("Only one file can be uploaded at a time.")}</li>
              <li>{t("The data must be organized according to the format of the following template:")}</li>
            </ul>
            <a href="/pre_visitor_registration_template.csv" download>
              <FileDownloadIcon sx={{ fontSize: 15 }} />
              {t('Downloadable template')}
            </a>
          </div>
          <Stack className='stack-import' spacing={2}>
            <label className="pre-label-form">
              1. {t('Select the date.')}
            </label>
            <Grid
              container
              spacing={isLarge ? 0 : 5}
              className="form-input-group"
            >
              <Grid item xs={12} md>
                <FormControl fullWidth>
                  <TextField
                    value={getFormateDate()}
                    onClick={onChangeDate}
                    variant="filled"
                    fullWidth
                    inputRef={inputDateRef}
                  />
                </FormControl>
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Grid item xs={12} md>
                <FormControl fullWidth>
                  <TextField
                    value={getFormateTime()}
                    onClick={onChangeTime}
                    disabled={!date}
                    variant="filled"
                    fullWidth
                    inputRef={inputHourRef}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <label
              className={`pre-label-form ${!date ? 'disabled' : ''}`}>
              2. {t('Select the site and building to which your visitors will go.')}
            </label>
            <Grid
              container
              spacing={isLarge ? 0 : 5}
              className="form-input-group"
            >
              <Grid item xs={12} md>
                <FormControl fullWidth>
                  <Select
                    ref={selectSiteRef}
                    variant="filled"
                    labelId="site-label"
                    value={site}
                    disabled={!date}
                    onChange={onChangeSite}
                  >
                    {defaultMenuItem(t('site'))}
                    {sites.map(s =>
                      <MenuItem
                        key={s.id}
                        value={s.id}>
                        {s.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Grid item xs={12} md>
                <FormControl fullWidth>
                  <Select
                    disabled={site === -1}
                    variant="filled"
                    labelId="building-label"
                    value={building}
                    onChange={onChangeBuilding}
                  >
                    {defaultMenuItem(t('building'))}
                    {buildings.map(b =>
                      <MenuItem
                        key={b.id}
                        value={b.id}>
                        {b.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <label
              className={`pre-label-form ${building === -1 ? 'disabled' : ''}`}
            >
              3. {t('Upload the file in CSV format.')}
            </label>
            <DragAndDrop
              accept=".csv"
              onChange={onChangeFile}
              file={file}
              disabled={building === -1}
            />
          </Stack>
        </Stack>
        <div className='new-reservation-footer'>
          <Button
            fullWidth={!isLarge}
            variant="outlined"
            disabled={disabledSaveMultiInvitation()}
            onClick={saveMultiInvitations} >
            {t('CONTINUE')}
          </Button>
        </div>
      </Stack>
    )
  }, [building, buildings, date, defaultMenuItem, disabledSaveMultiInvitation, file, getFormateDate, getFormateTime, isLarge, onChangeDate, onChangeFile, onChangeSite, onChangeTime, saveMultiInvitations, site, sites, t])

  const resetForm = () => {
    setDate(null)
    setEntryTime(EIGHT_AM)
    setIdentification('')
    setName('')
    setLastname('')
    setEmail('')
    setComesFrom('')
    setEmailError('')
    setSite(-1)
    setBuilding(-1)
    setBuildings([])
    setSelectedCompany({})
  }

  const reserNewVisit = useCallback(() => {
    setInvitations([])
    setFile(undefined)
    setTab(VISIT)
    setActiveStep(0)
    resetForm()
  }, [])

  const slide0 = useCallback(() => {
    return (
      <TabContext value={tab}>
        <TabList
          className='new-visit-tabs-container'
          onChange={(_, newTab) => setTab(newTab)}
          aria-label="visits tabs"
        >
          <Tab label={t('Visitor')} value={VISIT} />
          <Tab label={t('Import list')} value={IMPORT} />
        </TabList>
        <div className="div-reservation slide0">
          <TabPanel value={VISIT}>
            {getVisitForm()}
          </TabPanel>
          <TabPanel value={IMPORT}>
            {getImportForm()}
          </TabPanel>
        </div>
      </TabContext>
    )
  }, [getImportForm, getVisitForm, t, tab])

  const slide1 = useCallback(() => {
    return (
      <>
        <div className="div-reservation">
          <h2>{t('visit details')}</h2>
          <div className='form-wrapper workplace'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={isLarge ? 8 : 5} alignItems="flex-end">
                <Grid item xs={12} md={7}>
                  <label
                    className="pre-label-form">
                    {t("When will you receive this person?")}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('date')}
                        </InputLabel>
                        <TextField
                          value={getFormateDate()}
                          onClick={onChangeDate}
                          variant="filled"
                          fullWidth
                          inputRef={inputDateRef}
                        />
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('hour')}
                        </InputLabel>
                        <TextField
                          value={getFormateTime()}
                          onClick={onChangeTime}
                          disabled={!date}
                          variant="filled"
                          fullWidth
                          inputRef={inputHourRef}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <label
                    className="pre-label-form">
                    {t("Where will you receive it?")}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('site')}
                        </InputLabel>
                        <Select
                          ref={selectSiteRef}
                          disabled={!date}
                          variant="filled"
                          labelId="site-label"
                          value={site}
                          onChange={onChangeSite}
                        >
                          {defaultMenuItem()}
                          {sites.map(s =>
                            <MenuItem
                              key={s.id}
                              value={s.id}>
                              {s.name}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('building')}
                        </InputLabel>
                        <Select
                          disabled={site === -1}
                          variant="filled"
                          labelId="building-label"
                          value={building}
                          onChange={onChangeBuilding}
                        >
                          {defaultMenuItem()}
                          {buildings.map(b =>
                            <MenuItem
                              key={b.id}
                              value={b.id}>
                              {b.name}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <label
                    className="pre-label-form">
                    {t("What is the reason for the visit?")}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="reason-label">
                          {t('reason')}
                        </InputLabel>
                        <Select
                          disabled={!building}
                          variant="filled"
                          labelId="reason-label"
                          value={reason}
                          onChange={onChangeReason}
                        >
                          {defaultMenuItem()}
                          {reasons.map((r, index) =>
                            <MenuItem
                              key={index}
                              value={r}>
                              {r}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </div>
          <div className='new-reservation-footer'>
            <Button
              fullWidth={!isLarge}
              variant="outlined"
              disabled={!isEnabledSave()}
              onClick={saveInvitation} >
              {t('ACCEPT')}
            </Button>
          </div>
        </div>
      </>
    )
  }, [building, buildings, date, defaultMenuItem, getFormateDate, getFormateTime, isEnabledSave, isLarge, onChangeDate, onChangeSite, onChangeTime, reason, reasons, saveInvitation, site, sites, t])

  const slide2 = useCallback(() => {
    return (
      <div className='Success-reservation'>
        <div className='success-box'>
          <CheckCircleIcon className="success-icon" />
          {tab === VISIT ?
            <>
              <h1 className='success-title'>{t('pre registration created successfully')}</h1>
              <div className="detail-list-wrapper">
                <ul className="detail-list">
                  <li><span style={{marginLeft: 0}}>{t('pre registration of visitors')}</span></li>
                  <li><EventIcon sx={iconSx} /><span>{formatDateMedMed(successItem.invitation_date)}</span> </li>
                  <li><PersonIcon sx={iconSx} /><span>{getFullNameGuest(successItem)}</span> </li>
                  <li><LocationCityIcon sx={iconSx} /><span>{getPlaceByID(successItem)}</span> </li>
                  <li><WatchLaterIcon sx={iconSx} /><span>{formatTime(removeTimezoneFromISO(successItem.invitation_time))}</span> </li>
                  <li><NotesIcon sx={iconSx} /><span>{successItem.reason}</span></li>
                </ul>
              </div>
            </>
            :
            <h1 className='success-title'>{t('Multiple pre-registration has been successfully created.')}</h1>
          }
          <Stack spacing={2} className='success-footer'>
            <Button
              variant="outlined"
              onClick={reserNewVisit} >
              {t('NEW PRE-REGISTRATION')}
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/visitors")} >
              {t('SEE PRE REGISTRATIONS')}
            </Button>
          </Stack>
        </div>
      </div>
    )
  }, [getPlaceByID, navigate, reserNewVisit, successItem, t, tab])

  const getStep = useCallback(() => {
    if (activeStep === 0) return slide0()
    if (activeStep === 1) return slide1()
    if (activeStep === 2) return slide2()
    return ''
  }, [activeStep, slide0, slide1, slide2])

  return (
    <div className='NewVisitor New-reservation'>
      <Layout2
        title={t('pre registration of visitors')}
        goBack={() => navigate("/visitors")}>
        <div className="new-reservation">
          {horizontalSteeper()}
          {getStep()}
          <SwipeableCalendar
            minDate={today}
            date={calendarDate}
            open={openCalendar}
            onChange={date => setCalendarDate(date)}
            setOpen={setOpenCalendar}
            onContinue={onContinue}
            onClose={onCloseCalendar}
          />
          <SwipeableTimePicker
            time={timePickerValue}
            open={openTimePicker}
            setTime={setTimePickerValue}
            minTime={getMinTime()}
            setOpen={setOpenTimePicker}
            onContinue={onContinueTimePicker}
            onClose={onCloseTimePicker}
          />
        </div>
      </Layout2>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    company: state.profile.company
  }
}

const mapDispatchToProps = {
  dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVisitor)

