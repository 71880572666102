import { Types } from "../actionTypes"
import { api, popTokens, setTokens } from '../../api/api'

import {
  setLog,
  types as errorTypes
} from '../../common/utilities/LogError'
import { MAX_401_ATTEMPTS } from "../../variables"

let global_baseURL = ''
let global_dispatch = {}
let iteration = 0

const cleanPayload = data => {
  const token = data.tokens[0] || {}

  const {
    enabled_parking = false,
    enabled_workplace = false,
    enabled_room_reservations = false,
    enabled_manual_checkin = false,
    enabled_parking_reservations_manual_checkin = false,
    enabled_room_reservations_manual_checkin = false,
    enabled_parking_assignations_manual_checkin = false,
    enabled_desk_assignations_manual_checkin = false,
    enabled_parking_schedule = false,
    enabled_visits = false,
    enabled_cafeteria = false,
  } = token

  return {
    ...data,
    enabled_parking,
    enabled_workplace,
    enabled_room_reservations,
    enabled_manual_checkin,
    enabled_parking_reservations_manual_checkin,
    enabled_room_reservations_manual_checkin,
    enabled_parking_assignations_manual_checkin,
    enabled_desk_assignations_manual_checkin,
    enabled_parking_schedule,
    enabled_visits,
    enabled_cafeteria
  }
}

const messageListener = event => {
  const { data = {}, origin } = event
  const url = global_baseURL.replace('/api', '').replace('https://', '')

  const {
    access_token,
    client,
    uid
  } = data

  if (
    !origin.includes(url) ||
    !(access_token && client && uid)
  ) return

  window.global_tab?.close()
  window.removeEventListener("message", messageListener)

  setTokens({
    'access-token': access_token,
    'client': client,
    'token-type': 'Bearer',
    'uid': uid
  })

  const config = {
    headers: {
      'access-token': access_token,
      'client': client,
      'token-type': 'Bearer',
      'uid': uid
    }
  }

  api.get('/auth/validate_token', {}, config)
    .then(({ data }) => {
      global_dispatch({
        type: Types.SET_AUTH,
        payload: cleanPayload(data)
      })
    })
}

const ldapAuth = ({ password }, data) => {
  return function (dispatch) {
    const { user_name, provider } = data.auth_types[0]
    const _data = { user_name, provider, password }
    return api.post('/users/ldap/sign_in', _data)
      .then(({ data }) => {
        const { success, errors } = data
        if (!success) {
          if (errors === 'Usuario/contraseña inválido') {
            throw new Error('password is incorrect')
          }
          throw new Error('unknown error')
        }
        setTokens(data.tokens[0].token)
        dispatch({
          type: Types.SET_AUTH,
          payload: cleanPayload(data)
        })
      })
  }
}

const ssoAuth = (data, baseURL) => {
  return function (dispatch) {
    const { provider = '' } = data
    const route = `/users/saml/sign_in?web=true&provider=${provider}`
    global_baseURL = baseURL
    global_dispatch = dispatch
    window.addEventListener("message", messageListener, false)
    return `${baseURL}${route}`
  }
}

const oAuth = baseURL => {
  return function (dispatch) {
    const route = '/oauth/google_oauth2'
    global_baseURL = baseURL
    global_dispatch = dispatch
    window.addEventListener("message", messageListener, false)
    return `${baseURL}${route}`
  }
}

const deviseAuth = (payload, baseURL) => {
  return function (dispatch) {
    return api.post('/auth/sign_in', payload, { baseURL })
      .then(({ data }) => {
        dispatch({
          type: Types.SET_AUTH,
          payload: cleanPayload(data)
        })
      })
  }
}

const _dispatch = dispatch => {
  return dispatch
}

const setLoading = loading => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }
}

const setSnackbar = payload => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload
    })
  }
}

const setDomain = domain => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_DOMAIN,
      payload: domain
    })
  }
}

const logout = navigate => {
  return function (dispatch) {
    dispatch({ type: Types.CLEAN_AUTH })
    navigate('/login')
  }
}

const eval401Error = (navigate, call) => {
  return function (dispatch) {
    iteration++
    if (iteration < MAX_401_ATTEMPTS) {
      popTokens()
      call()
    } else logout(navigate)(dispatch)
  }
}

const defaultSuccess = r => {
  return function (dispatch) {
    iteration = 0
    setLoading(false)(dispatch)
    return r
  }
}

const defaultCatch = (error, navigate, currentFunction) => {
  return function (dispatch) {
    setLoading(false)
    if (error?.response?.status === 401) eval401Error(navigate, currentFunction)(dispatch)
    else if (error.response) {
      setLog({
        title: error.response.status,
        type: errorTypes.API_ERROR,
        message: error.response.data.errors,
        api_url: error.response.config.url,
        api_method: error.response.config.method,
        api_params: error.response.config.data
      })
    } else setLog({ type: errorTypes.API_ERROR, message: error.message })
  }
}

export {
  _dispatch,
  defaultCatch,
  defaultSuccess,
  eval401Error,
  logout,
  oAuth,
  ssoAuth,
  ldapAuth,
  deviseAuth,
  setLoading,
  setSnackbar,
  setDomain,

}