import React,
{
  useState,
  useCallback,
} from 'react'

import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'
import Layout2 from '../../common/components/LayoutCreate/LayoutCreate'

import { useNavigate } from 'react-router-dom'

import {
  Button,
  Step,
  StepLabel,
  Radio,
  Divider,
  MenuList,
  ListItemText,
  ListItemIcon,
  Stepper,
  MenuItem,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material'

import {
  Groups as GroupsIcon,
  AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  DirectionsCar,
  Restaurant as RestaurantIcon,
} from '@mui/icons-material'


import './NewReservation.sass'

import {
  PARKING_RESERVATION,
  PARKING_DESK_RESERVATION,
  DESK_RESERVATION,
  ROOM_RESERVATION,
  VISIT_REGISTER,
  ENABLED_PW,
  CAFETERIA_RESERVATION
} from '../../variables'

import ParkingReservation from '../../common/components/ParkingReservation/ParkingReservation'
import WorkplaceReservation from '../../common/components/WorkplaceReservation/WorkplaceReservation'
import CafeteriaReservation from '../../common/components/CafeteriaReservation/CafeteriaReservation'
import ModalNotification from '../../common/components/ModalNotification/ModalNotification'
import CustomWorkplaceIcons from '../../common/utilities/CustomWorkplaceIcons'

const steps = [0, 1, 2]

function NewReservations(props) {
  const {
    enabledRoom,
    enabledVisits,
    enabledParking,
    enabledPW,
    enabledWorkplace,
    enabledDesk,
    parkingTotalReservations,
    desksTotalReservations,
    maxBookingPerPerson,
    enabledMultiplesDaysParkingReservations,
    enabledCafeteria,
    cafeteriaTotalOrders,
    cafeteriaMaxOrdersByPerson,
    roomsTotalReservations,
    deskMaxBookingPerPerson,
    roomMaxBookingPerPerson,
    workplaceCustomLabel,
    workplaceCustomIconIndex,
    enabledWorkplaceCustomIcon
  } = props

  const getDefaultSelectedType = () => {
    if (
      enabledParking &&
      enabledDesk &&
      enabledPW &&
      !enabledMultiplesDaysParkingReservations
    ) return PARKING_DESK_RESERVATION
    if (enabledParking) return PARKING_RESERVATION
    if (enabledWorkplace && enabledDesk) return DESK_RESERVATION
    if (enabledWorkplace && enabledRoom) return ROOM_RESERVATION
    return ''
  }

  const { t } = useTranslation()
  const navigate = useNavigate()

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const [activeStep, setActiveStep] = useState(0)
  const [selectedType, setSelectedType] = useState(getDefaultSelectedType())
  const [openMaxReservation, setOpenMaxReservation] = useState(false)
  const [messageMaxReservation, setMessageMaxReservation] = useState('')
  const [initDate, setInitDate] = useState()


  const handleBack = () => {
    if (activeStep - 1 === 0) {
      setSelectedType(getDefaultSelectedType())
    }
    setActiveStep(activeStep => activeStep - 1)
  }

  const maxReservationModal = useCallback(() => {
    return (
      <ModalNotification
        open={openMaxReservation}
        onAccept={() => setOpenMaxReservation(false)}
        title={t('maximum reserves reached')}
        paragraph={messageMaxReservation}
        cancelButton={false}
      />
    )
  }, [messageMaxReservation, openMaxReservation, t])

  const horizontalSteeper = useCallback(() => {
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          {(isLarge && activeStep === 1) && (
            <Button
              color="inherit"
              onClick={handleBack}
              startIcon={
                <ArrowBackIcon className="back-steeper" />
              }
            >
              {t('back')}
            </Button>
          )}
        </Grid>
        <Grid item>
          <Stepper activeStep={activeStep}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel />
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs>
        </Grid>
      </Grid>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, isLarge])

  const radioControlProps = useCallback(item => ({
    onChange: event => setSelectedType(event.target.value),
    checked: selectedType === item,
    value: item,
    name: 'radio-type',
    className: 'radio-type',
    inputProps: { 'aria-label': item },
  }), [selectedType])

  const setMaxReservationData = useCallback(({ count = 0, type = '' }) => {
    setMessageMaxReservation(
      t('you can only have x active type reservations and you have reached that maximum',
        { x: count, type: t(type).toLocaleLowerCase() }
      ))
    setOpenMaxReservation(true)
  }, [t])

  const evalContinue = useCallback(() => {
    if (selectedType === VISIT_REGISTER) return navigate('/new-visitor')

    if (
      (selectedType === PARKING_DESK_RESERVATION || selectedType === PARKING_RESERVATION) &&
      maxBookingPerPerson > 0 &&
      parkingTotalReservations >= maxBookingPerPerson
    ) return setMaxReservationData({ count: maxBookingPerPerson, type: 'parkings' })

    if (
      (selectedType === PARKING_DESK_RESERVATION || selectedType === DESK_RESERVATION) &&
      deskMaxBookingPerPerson > 0 &&
      desksTotalReservations >= deskMaxBookingPerPerson
    ) return setMaxReservationData({ count: deskMaxBookingPerPerson, type: 'desks' })

    if (
      selectedType === ROOM_RESERVATION &&
      roomMaxBookingPerPerson > 0 &&
      roomsTotalReservations >= roomMaxBookingPerPerson
    ) return setMaxReservationData({ count: roomMaxBookingPerPerson, type: 'rooms' })

    if (
      selectedType === CAFETERIA_RESERVATION &&
      cafeteriaTotalOrders === cafeteriaMaxOrdersByPerson
    ) return setMaxReservationData({ count: cafeteriaMaxOrdersByPerson, type: 'cafeteria' })

    if (
      selectedType === PARKING_DESK_RESERVATION ||
      selectedType === PARKING_RESERVATION ||
      selectedType === DESK_RESERVATION ||
      selectedType === ROOM_RESERVATION ||
      selectedType === CAFETERIA_RESERVATION
    ) setActiveStep(1)

  }, [selectedType, parkingTotalReservations, maxBookingPerPerson, setMaxReservationData, desksTotalReservations, deskMaxBookingPerPerson, roomsTotalReservations, roomMaxBookingPerPerson, navigate, cafeteriaTotalOrders, cafeteriaMaxOrdersByPerson])


  const slide0 = useCallback(() => {
    return (
      <div className="slide-0-wrapper">
        <h2 className="title-type">{t('what would you like to reserve?')}</h2>
        <MenuList dense>
          {enabledParking && enabledDesk && enabledPW && !enabledMultiplesDaysParkingReservations &&
            <MenuItem onClick={() => setSelectedType(PARKING_DESK_RESERVATION)}>
              <ListItemIcon>
                <AirlineSeatReclineNormalIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('parking+desk reservation')}</ListItemText>
              <Radio {...radioControlProps(PARKING_DESK_RESERVATION)} />
            </MenuItem>
          }
          {enabledParking && enabledDesk && enabledPW && !enabledMultiplesDaysParkingReservations && <Divider />}
          {enabledParking &&
            <MenuItem onClick={() => setSelectedType(PARKING_RESERVATION)}>
              <ListItemIcon>
                <DirectionsCar fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('parking')}</ListItemText>
              <Radio {...radioControlProps(PARKING_RESERVATION)} />
            </MenuItem>
          }
          {enabledParking && <Divider />}
          {enabledWorkplace && enabledDesk &&
            <MenuItem onClick={() => setSelectedType(DESK_RESERVATION)}>
              <ListItemIcon>
                { 
                  enabledWorkplaceCustomIcon ?
                  <CustomWorkplaceIcons 
                    index={workplaceCustomIconIndex} 
                    fontSize="small"
                    color={"#0000008A"}
                    width={20}
                  />
                  :
                  <AirlineSeatReclineNormalIcon fontSize="small" />
                }
              </ListItemIcon>
              <ListItemText>{workplaceCustomLabel ? workplaceCustomLabel : t('workplace')}</ListItemText>
              <Radio {...radioControlProps(DESK_RESERVATION)} />
            </MenuItem>
          }
          {enabledWorkplace && enabledDesk && <Divider />}
          {enabledWorkplace && enabledRoom &&
            <MenuItem onClick={() => setSelectedType(ROOM_RESERVATION)}>
              <ListItemIcon>
                <GroupsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('room')}</ListItemText>
              <Radio {...radioControlProps(ROOM_RESERVATION)} />
            </MenuItem>
          }
          {enabledWorkplace && enabledRoom && <Divider />}
          {enabledVisits &&
            <MenuItem onClick={() => setSelectedType(VISIT_REGISTER)}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('pre registration of visitors')}</ListItemText>
              <Radio {...radioControlProps(VISIT_REGISTER)} />
            </MenuItem>
          }
          {enabledVisits && <Divider />}
          {enabledCafeteria &&
            <MenuItem onClick={() => setSelectedType(CAFETERIA_RESERVATION)}>
              <ListItemIcon>
                <RestaurantIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('Cafeteria Order')}</ListItemText>
              <Radio {...radioControlProps(CAFETERIA_RESERVATION)} />
            </MenuItem>
          }
          {enabledCafeteria && <Divider />}
        </MenuList>
        <Button
          fullWidth
          variant="outlined"
          disabled={selectedType === ''}
          onClick={evalContinue} >
          {t('continue')}
        </Button>
      </div >
    )
  }, [
    enabledDesk,
    enabledParking,
    enabledRoom,
    enabledVisits,
    enabledWorkplace,
    enabledMultiplesDaysParkingReservations,
    enabledCafeteria,
    evalContinue,
    enabledPW,
    radioControlProps,
    selectedType,
    t,
    workplaceCustomLabel,
    workplaceCustomIconIndex,
    enabledWorkplaceCustomIcon
  ])

  const slide1 = useCallback(() => {
    if (
      selectedType === PARKING_RESERVATION ||
      selectedType === PARKING_DESK_RESERVATION
    ) {
      return (
        <ParkingReservation
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setInitDate={setInitDate}
        />
      )
    } else if (
      selectedType === DESK_RESERVATION ||
      selectedType === ROOM_RESERVATION
    ) {
      return (
        <WorkplaceReservation
          initDate={initDate}
          selectedType={selectedType}
        />
      )
    } else if (selectedType === CAFETERIA_RESERVATION) {
      return (
        <CafeteriaReservation />
      )
    }
  }, [initDate, selectedType])

  const getStep = useCallback(() => {
    if (activeStep === 0) return slide0()
    if (activeStep === 1) return slide1()
    return ''
  }, [activeStep, slide0, slide1])

  return (
    <div className='New-reservation'>
      <Layout2
        title={t('new reservation')}
        goBack={() => navigate("/")}>
        <div className="new-reservation">
          {horizontalSteeper()}
          {getStep()}
        </div>
      </Layout2>
      {maxReservationModal()}
    </div>
  )
}


const mapStateToProps = state => {
  return {
    enabledVisits: state.profile.enabled_visits,
    enabledParking: state.profile.enabled_parking,
    parkingTotalReservations: state.parking.total,
    desksTotalReservations: state.workplace.desksTotal,
    roomsTotalReservations: state.workplace.roomsTotal,
    enabledWorkplace: state.profile.enabled_workplace,
    enabledPW: ENABLED_PW.includes(state.profile.domain),
    enabledRoom: state.profile.enabled_room_reservations,
    enabledDesk: state.profile.company.enabled_desk_reservations,
    maxBookingPerPerson: state.profile.company.max_bookings_per_person,
    workplaceMaxBookingPerPerson: state.profile.company.workplace_max_booking_per_person,
    enabledCafeteria: state.profile.company.enabled_cafeteria,
    cafeteriaTotalOrders: state.cafeteria.total,
    cafeteriaMaxOrdersByPerson: state.profile.company.cafeteria_max_order_by_person,
    deskMaxBookingPerPerson: state.profile.company.workplace_max_booking_per_person,
    roomMaxBookingPerPerson: state.profile.company.workplace_max_room_reservations,
    enabledMultiplesDaysParkingReservations: state.profile.company.enabled_multiples_parking_days_reservations,
    workplaceCustomLabel: state.profile.company.workplace_custom_label,
    workplaceCustomIconIndex: state.profile.company.workplace_custom_icon_index,
    enabledWorkplaceCustomIcon: state.profile.company.enabled_workplace_custom_icon
  }
}

export default connect(mapStateToProps)(NewReservations)

